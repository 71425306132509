import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Select,
  MenuItem,
  colors,
  Tooltip
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import {
  CalendarMonthOutlined,
  CheckBoxRounded,
  CheckOutlined,
  DisabledByDefaultTwoTone,
  ErrorOutlineRounded,
  ErrorOutlined,
  LocationCityOutlined,
  SaveAsTwoTone,
  TimelineOutlined,
  WarningAmberOutlined
} from '@mui/icons-material';
import { applications_mock } from 'src/__mock__/data';
import { red } from '@mui/material/colors';

function getButton(status: string) {
  switch (status) {
    case 'notStarted':
      return (
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          fullWidth
          color="primary"
        >
          Start
        </Button>
      );

    case 'draft':
      return (
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          fullWidth
          color="success"
        >
          Continue
        </Button>
      );

    case 'complete':
      return (
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          fullWidth
          color="primary"
        >
          View
        </Button>
      );

    case 'correction':
      return (
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          fullWidth
          color="warning"
        >
          Fix Errors
        </Button>
      );

    default:
      return 'Unknown';
  }
}

function getStatusLabel(status: string) {
  if (status === 'notStarted')
    return (
      <Tooltip title="Not Filled">
        <DisabledByDefaultTwoTone color="error" />
      </Tooltip>
    );
  if (status === 'draft')
    return (
      <Tooltip title="In Draft">
        <SaveAsTwoTone color="warning" />
      </Tooltip>
    );
  if (status === 'complete')
    return (
      <Tooltip title="Details Added">
        <CheckBoxRounded color="success" />
      </Tooltip>
    );
  if (status === 'correction')
    return (
      <Tooltip title="Correction">
        <ErrorOutlined color="error" />
      </Tooltip>
    );

  return <></>;
}

function Applications({ applications }) {
  const [allApplications, setAllApplications] = useState(applications);
  const [filteredApplications, setFilteredApplications] =
    useState(allApplications);
  const [filter, setFilter] = useState('all');

  const handleSelectChange = (e) => {
    const filterValue = e.target.value;
    const applications = allApplications.filter(
      (application) =>
        filterValue === 'all' || application.stage === filterValue
    );
    setFilteredApplications(applications);
    setFilter(e.target.value);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pb: 3
        }}
      >
        <Typography variant="h3">Applications</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label="Age"
          onChange={handleSelectChange}
        >
          <MenuItem value={'all'}>All</MenuItem>
          <MenuItem value={'notStarted'}>Not Started</MenuItem>
          <MenuItem value={'draft'}>Draft</MenuItem>
          <MenuItem value={'complete'}>Complete</MenuItem>
          <MenuItem value={'correction'}>Correction</MenuItem>
        </Select>
      </Box>
      <Grid container spacing={3}>
        {filteredApplications.length > 0 ? (
          filteredApplications.map(({ _id, job, stage }) => (
            <Grid key={_id} xs={12} sm={6} md={4} xl={3} item>
              <Card
                sx={{
                  px: 1
                }}
              >
                <CardContent>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography variant="h4" noWrap>
                      {job.title}
                    </Typography>
                    {getStatusLabel(stage)}
                  </Box>

                  <Box mt={2} display={'flex'} justifyContent={'space-between'}>
                    <Typography
                      variant="subtitle1"
                      noWrap
                      display={'flex'}
                      gap={1}
                    >
                      <LocationCityOutlined />
                      {job.location}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      noWrap
                      display={'flex'}
                      gap={1}
                    >
                      <CalendarMonthOutlined />
                      24 May 2023
                    </Typography>
                  </Box>

                  <Typography mt={2} variant="body2" noWrap>
                    {job.overview}
                  </Typography>
                  <Box>
                    <Link
                      to={
                        stage === 'complete'
                          ? `/view/${_id}`
                          : `/application/${_id}`
                      }
                    >
                      {getButton(stage)}
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid xs={12} item>
            <Card
              sx={{
                px: 1
              }}
            >
              <CardContent>
                <Typography>
                  We could not find any applications assigned to you.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Applications;
