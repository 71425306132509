import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, Divider, MenuItem } from '@mui/material';
import { FieldArray, Form, Formik, getIn, useFormik } from 'formik';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getSingleApplication } from 'src/redux/actions/applications';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { useParams } from 'react-router-dom';

type Props = {
  open: boolean;
  handleModalState: any;
  getSingleApplication;
  formData?;
};

const submitWorkingTimeRegulations = async (values) => {
  try {
    await backend.post('/forms/applications/working-time-regulations', {
      applicationId: values.applicationId,
      workForMoreThanAnAverage: values.workForMoreThanAnAverage
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const WorkingTimeRegulations = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('workingTimeRegulationsModal', 'close')}
    >
      <Formik
        initialValues={{
          workForMoreThanAnAverage: formData?.workForMoreThanAnAverage || 'No'
        }}
        onSubmit={async (values) => {
          if (
            await submitWorkingTimeRegulations({ ...values, applicationId })
          ) {
            handleModalState('workingTimeRegulationsModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Working Time Regulations</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="caption">
                Please inform Pulse Care by completing the options below if you
                wish to opt in or to opt out of the working time (amendment)
                regulations 2003, which limits indicate a 48 hours average
                working week.
              </Typography>
              <Grid mt={2} container spacing={2}>
                <Grid item xs={12}>
                  <Typography mb={1}>
                    Are you willing to work for more than an average 48 hours a
                    week?
                  </Typography>
                  <TextField
                    select
                    size="small"
                    id="workForMoreThanAnAverage"
                    name={`workForMoreThanAnAverage`}
                    // label="workForMoreThanAnAverage"
                    fullWidth
                    variant="outlined"
                    value={values.workForMoreThanAnAverage}
                    onChange={handleChange}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  handleModalState('workingTimeRegulationsModal', 'close')
                }
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(WorkingTimeRegulations);
