import { backend } from 'src/services/http';
import {
  APPLICATIONS_FETCH_FAILED,
  APPLICATIONS_FETCH_SUCCESS,
  APPLICATION_SINGLE_FETCH_SUCCESS,
  APPLICATION_SINGLE_LOADING
} from './types';
import { toast } from 'react-toastify';

export const getApplications = () => async (dispatch) => {
  try {
    const res = await backend.get('/forms/applications');
    dispatch({
      type: APPLICATIONS_FETCH_SUCCESS,
      payload: res.data.data.applications
    });
  } catch (error) {
    const errors = error?.response?.data?.errors;
    errors.forEach((error) => toast(error.msg, { type: 'error' }));
  }
};

export const getSingleApplication = (applicationId) => async (dispatch) => {
  try {
    dispatch({
      type: APPLICATION_SINGLE_LOADING
    });
    const res = await backend.get(`/forms/applications/${applicationId}`);
    dispatch({
      type: APPLICATION_SINGLE_FETCH_SUCCESS,
      payload: res.data.data
    });
  } catch (error) {
    const errors = error?.response?.data?.errors;
    errors.forEach((error) => toast(error.msg, { type: 'error' }));
  }
};
