import { CheckOutlined } from '@mui/icons-material';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import React from 'react';
import DocumentsModal from './modals/DocumentsModal';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import FormStatus from '../FormStatus';

const Documents = ({ application }) => {
  const [modalOpen, setModalOpen] = React.useState({
    documentsModal: false
  });

  const handleModalState = (
    modal: 'documentsModal',
    changeType: 'open' | 'close'
  ) => {
    switch (modal) {
      case 'documentsModal':
        setModalOpen((modalState) => ({
          ...modalState,
          documentsModal: changeType === 'open' ? true : false
        }));
        break;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Documents / Attachments
              </Typography>
              <FormStatus stage={application?.documents?.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Documents and necessary attachments for this application
            </Typography>

            <Button
              onClick={() => handleModalState('documentsModal', 'open')}
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>
      {/* Modals */}
      <DocumentsModal
        open={modalOpen.documentsModal}
        handleModalState={handleModalState}
        formData={application.documents}
      />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  application: state.applicationsReducer.currentApplication
}))(Documents);
