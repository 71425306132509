import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Divider, Autocomplete, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getSingleApplication } from 'src/redux/actions/applications';
import { backend } from 'src/services/http';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { useParams } from 'react-router-dom';
import { competencies, experiences } from 'src/data/skills';

type Props = {
  open: boolean;
  handleModalState: any;
  getSingleApplication;
  formData;
};

const submitNursingCompetencyChecklist = async (values) => {
  try {
    await backend.post(
      '/forms/applications/nursing-competency-checklist',
      values
    );
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const NursingCompetencyChecklist = ({
  open,
  handleModalState,
  getSingleApplication,
  formData
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() =>
        handleModalState('nursingCompetencyChecklistModal', 'close')
      }
    >
      <Formik
        initialValues={{
          competencies: formData?.competencies || [],
          experiences: formData?.experiences || []
        }}
        onSubmit={async (values) => {
          if (
            await submitNursingCompetencyChecklist({ ...values, applicationId })
          ) {
            handleModalState('nursingCompetencyChecklistModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ setFieldValue, values, handleChange }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Nursing Competency Checklist</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Grid mt={2} container spacing={2}>
                <Grid xs={12} item>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    // limitTags={2}
                    id="competencies"
                    options={competencies}
                    value={values.competencies}
                    onChange={(_, values) =>
                      setFieldValue('competencies', values)
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ fontSize: '0.6rem' }}
                        size="small"
                        {...params}
                        variant="outlined"
                        label="Competency & Accountability"
                        placeholder="Competency & Accountability"
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} item>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    // limitTags={2}
                    id="experiences"
                    options={experiences}
                    value={values.experiences}
                    onChange={(_, values) =>
                      setFieldValue('experiences', values)
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ fontSize: '0.6rem' }}
                        size="small"
                        {...params}
                        variant="outlined"
                        label="Experienced In"
                        placeholder="Experienced In"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  handleModalState('nursingCompetencyChecklistModal', 'close')
                }
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(
  NursingCompetencyChecklist
);
