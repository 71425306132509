import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Divider } from '@mui/material';
import { Form, Formik } from 'formik';
import { getSingleApplication } from 'src/redux/actions/applications';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { useParams } from 'react-router-dom';

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const submitPaymentInfo = async (values) => {
  try {
    await backend.post('/forms/applications/payment-info', values);
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const PaymentInfoModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('paymentInfo', 'close')}
    >
      <Formik
        initialValues={{
          accountNumber: formData.accountNumber || '',
          bankingName: formData.bankingName || '',
          bankName: formData.bankName || '',
          sortCode: formData.sortCode || ''
        }}
        onSubmit={async (values) => {
          if (await submitPaymentInfo({ ...values, applicationId })) {
            handleModalState('paymentInfo', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ setFieldValue, values, handleChange }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Payment Information</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Grid mt={2} container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="accountNumber"
                    name="accountNumber"
                    label="Personal Account Number"
                    fullWidth
                    variant="outlined"
                    value={values.accountNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="bankingName"
                    name="bankingName"
                    label="Banking Name"
                    fullWidth
                    variant="outlined"
                    value={values.bankingName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="bankName"
                    name="bankName"
                    label="Bank Name"
                    fullWidth
                    variant="outlined"
                    value={values.bankName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="sortCode"
                    name="sortCode"
                    label="Sort Code"
                    fullWidth
                    variant="outlined"
                    value={values.sortCode}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleModalState('paymentInfo', 'close')}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default connect(null, { getSingleApplication })(PaymentInfoModal);
