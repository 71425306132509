import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { login } from 'src/redux/actions/auth';
import { RootState } from 'src/redux/store';
import { toast } from 'react-toastify';
import { backend } from 'src/services/http';
import { register } from 'numeral';
import { useSelector } from 'react-redux';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © ' + new Date().getFullYear() + ' '}
      <a target="blank" href="https://mohsinyaqoob.com/">
        Better Software
      </a>{' '}
      {}
    </Typography>
  );
}

const resetPassword = async (email) => {
  try {
    await backend.post('/forms/reset-password', {
      email
    });
    return true;
  } catch (error) {
    const errors: [] = error.response.data.errors;
    errors.forEach((error: any) => toast(error.msg, { type: 'error' }));
    return false;
  }
};

const submitPassCode = async (email, passCode) => {
  try {
    await backend.post('/forms/verify-reset-password', {
      email,
      passCode
    });
    return true;
  } catch (error) {
    const errors: [] = error.response.data.errors;
    errors.forEach((error: any) => toast(error.msg, { type: 'error' }));
    return false;
  }
};

const submitNewPassword = async ({
  email,
  passCode,
  newPassword,
  cPassword
}) => {
  try {
    await backend.post('/forms/set-new-password', {
      email,
      passCode,
      newPassword,
      cPassword
    });
    return true;
  } catch (error) {
    const errors: [] = error.response.data.errors;
    errors.forEach((error: any) => toast(error.msg, { type: 'error' }));
    return false;
  }
};

const ResetPasword = () => {
  const [buttonEnabled, setButtonEnabled] = React.useState(true);
  const [resetStep, setResetStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    email: '',
    passCode: '',
    newPassword: '',
    cPassword: ''
  });
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => ({
    state: state.authReducer
  }));

  if (!auth.state.loading && auth.state.loggedIn) {
    navigate('/dashboard');
  }

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    setButtonEnabled(false);
    if (await resetPassword(formData.email)) {
      toast('An auth code was sent to your registered email', {
        type: 'success'
      });
      setResetStep(1);
    }
    setButtonEnabled(true);
  };

  const handlePassCodeSubmit = async (e) => {
    e.preventDefault();
    setButtonEnabled(false);
    if (await submitPassCode(formData.email, formData.passCode)) {
      toast('Please setup a new password for your account', {
        type: 'success'
      });
      setResetStep(2);
    }
    setButtonEnabled(true);
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    setButtonEnabled(false);
    if (formData.newPassword !== formData.cPassword) {
      toast('New password and confirm password do not match', {
        type: 'error'
      });
      setButtonEnabled(true);
      return;
    }
    if (await submitNewPassword({ ...formData })) {
      toast('Please login with your new password', {
        type: 'success'
      });
      navigate('/login');
    }
    setButtonEnabled(true);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    // setFormData({ email: '', passCode: '', cPassword: '', newPassword: '' });
  }, []);

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1622253694238-3b22139576c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1965&q=80)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {resetStep === 0 ? (
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" mb={2}>
              Input Email to reset password
            </Typography>
            <Typography variant="h3">Step 1/3</Typography>
            <form onSubmit={handleResetSubmit}>
              <Box sx={{ mt: 1 }}>
                <TextField
                  sx={{ fontSize: '0.6rem' }}
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type="text"
                  onChange={handleChange}
                  value={formData.email}
                />
                <Button
                  disabled={!buttonEnabled}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Reset Password
                </Button>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Link to="/login">Or Log into your account</Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </form>
          </Box>
        ) : resetStep === 1 ? (
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" mb={2}>
              Input Authentication Code
            </Typography>
            <Typography variant="h3">Step 2/3</Typography>
            <form onSubmit={handlePassCodeSubmit}>
              <Box sx={{ mt: 1 }}>
                <TextField
                  sx={{ fontSize: '0.6rem', textAlign: 'center' }}
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="passCode"
                  label="Authentication Code"
                  type="text"
                  onChange={handleChange}
                  value={formData.passCode}
                  inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
                />
                <Button
                  disabled={!buttonEnabled}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Verify Authentication Code
                </Button>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Link to="/login">Or Log into your account</Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </form>
          </Box>
        ) : resetStep === 2 ? (
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" mb={2}>
              Set a new password
            </Typography>
            <Typography variant="h3">Step 3/3</Typography>
            <form onSubmit={handleNewPasswordSubmit}>
              <Box sx={{ mt: 1 }}>
                <TextField
                  sx={{ fontSize: '0.6rem' }}
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  onChange={handleChange}
                  value={formData.newPassword}
                />
                <TextField
                  sx={{ fontSize: '0.6rem' }}
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="cPassword"
                  label="Confirm Password"
                  type="password"
                  onChange={handleChange}
                  value={formData.cPassword}
                />
                <Button
                  disabled={!buttonEnabled}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Link to="/login">Or Log into your account</Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </form>
          </Box>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default ResetPasword;
