import saveAs from 'file-saver';
import { backend } from './http';
import { renderHttpErrors } from 'src/helpers/toast';

export const downloadFile = async (filePath, applicationId) => {
  try {
    const res = await backend.post(
      '/forms/applications/download-file',
      { filePath, applicationId },
      { responseType: 'blob' }
    );

    const blob = new Blob([res.data]);
    saveAs(blob, filePath);
  } catch (error) {
    renderHttpErrors(error);
  }
};
