import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Typography,
  Divider,
  MenuItem,
  Box,
  List,
  ListItem
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import DateSelector from 'src/components/DateSelector';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { getSingleApplication } from 'src/redux/actions/applications';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';

type Props = {
  open: boolean;
  handleModalState: any;
  formData: any;
  getSingleApplication;
};

const startIndex = 100;
const previousAddressFields = {
  dateMovedIn: new Date(),
  dateMovedOut: new Date(),
  addressLine1: '',
  addressLine2: '',
  cityTown: '',
  country: '',
  postCode: ''
};

const submitAddressHistory = async (values) => {
  try {
    await backend.post('/forms/applications/address-history', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const AddressHistoryModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('addressHistoryModal', 'close')}
    >
      <Formik
        initialValues={{
          currentAddress: {
            dateMovedIn:
              new Date(formData?.currentAddress?.dateMovedIn) || new Date(),
            addressLine1: formData?.currentAddress?.addressLine1,
            addressLine2: formData?.currentAddress?.addressLine2,
            cityTown: formData?.currentAddress?.cityTown,
            country: formData?.currentAddress?.country,
            postCode: formData?.currentAddress?.postCode
          },
          changedAddress: formData?.changedAddress || 'No',
          previousAddress: formData?.previousAddress?.map((address) => ({
            dateMovedIn: new Date(address?.dateMovedIn) || new Date(),
            dateMovedOut: new Date(address?.dateMovedOut) || new Date(),
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityTown: address?.cityTown || '',
            country: address?.country || '',
            postCode: address?.postCode || ''
          })),
          everLivedOutsideUK: formData?.everLivedOutsideUK || 'No',
          anyConvictions: formData?.anyConvictions || '',
          consent: formData?.consent || 'NO'
        }}
        onSubmit={async (values) => {
          if (await submitAddressHistory({ ...values, applicationId })) {
            handleModalState('addressHistoryModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Address History</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="subtitle1">
                Please provide your complete 5 year address history with correct
                postcodes (UK and outside UK)
              </Typography>
              <Grid container spacing={2} mt={2}>
                {/* Current Address */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Typography variant="body1" fontWeight={'bold'} mb={1}>
                      Current Address
                    </Typography>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <DateSelector
                        label="Date moved in"
                        handleChange={(date) => {
                          setFieldValue('currentAddress.dateMovedIn', date);
                        }}
                        value={values.currentAddress.dateMovedIn}
                      />
                      <TextField
                        name="currentAddress.addressLine1"
                        value={values.currentAddress.addressLine1}
                        fullWidth
                        onChange={handleChange}
                        label="Address Line 1"
                        placeholder="Address Line 1"
                        size="small"
                      />
                      <TextField
                        name="currentAddress.addressLine2"
                        value={values.currentAddress.addressLine2}
                        fullWidth
                        onChange={handleChange}
                        label="Address Line 2"
                        placeholder="Address Line 2"
                        size="small"
                      />
                      <TextField
                        name="currentAddress.cityTown"
                        value={values.currentAddress.cityTown}
                        fullWidth
                        onChange={handleChange}
                        label="City / Town"
                        placeholder="City / Town"
                        size="small"
                      />
                      <TextField
                        name="currentAddress.country"
                        value={values.currentAddress.country}
                        fullWidth
                        onChange={handleChange}
                        label="Country"
                        placeholder="Country"
                        size="small"
                      />
                      <TextField
                        name="currentAddress.postCode"
                        value={values.currentAddress.postCode}
                        fullWidth
                        onChange={handleChange}
                        label="Post Code"
                        placeholder="Post Code"
                        size="small"
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* Changed Address */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <TextField
                        name="changedAddress"
                        value={values.changedAddress}
                        fullWidth
                        onChange={handleChange}
                        label="Have you ever changed your address?"
                        placeholder="Have you ever changed your address?"
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                  </Box>
                </Grid>

                {/* Previous Address */}
                {values.changedAddress === 'Yes' && (
                  <Grid item xs={12}>
                    <FieldArray name="previousAddress">
                      {({ remove, push }) => {
                        return values.previousAddress.map((address, idx) => {
                          return (
                            <React.Fragment>
                              <Box
                                mt={values.previousAddress.length > 0 ? 2 : 0}
                                bgcolor={'#f8f8f8'}
                                p={2}
                                borderRadius={1}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight={'bold'}
                                  mb={1}
                                >
                                  Previous Address ({idx + 1})
                                </Typography>
                                <Box
                                  className="innerInputContainer"
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={2}
                                >
                                  <DateSelector
                                    label="Date moved in"
                                    handleChange={(date) => {
                                      setFieldValue(
                                        `previousAddress[${idx}].dateMovedIn`,
                                        date
                                      );
                                    }}
                                    value={
                                      values.previousAddress[idx].dateMovedIn
                                    }
                                  />
                                  <DateSelector
                                    label="Date moved out"
                                    handleChange={(date) => {
                                      setFieldValue(
                                        `previousAddress[${idx}].dateMovedOut`,
                                        date
                                      );
                                    }}
                                    value={
                                      values.previousAddress[idx].dateMovedOut
                                    }
                                  />
                                  <TextField
                                    name={`previousAddress[${idx}].addressLine1`}
                                    value={
                                      values.previousAddress[idx].addressLine1
                                    }
                                    fullWidth
                                    onChange={handleChange}
                                    label="Address Line 1"
                                    placeholder="Address Line 1"
                                    size="small"
                                  />
                                  <TextField
                                    name={`previousAddress[${idx}].addressLine2`}
                                    value={
                                      values.previousAddress[idx].addressLine2
                                    }
                                    fullWidth
                                    onChange={handleChange}
                                    label="Address Line 2"
                                    placeholder="Address Line 2"
                                    size="small"
                                  />
                                  <TextField
                                    name={`previousAddress[${idx}].cityTown`}
                                    value={values.previousAddress[idx].cityTown}
                                    fullWidth
                                    onChange={handleChange}
                                    label="City / Town"
                                    placeholder="City / Town"
                                    size="small"
                                  />
                                  <TextField
                                    name={`previousAddress[${idx}].country`}
                                    value={values.previousAddress[idx].country}
                                    fullWidth
                                    onChange={handleChange}
                                    label="Country"
                                    placeholder="Country"
                                    size="small"
                                  />
                                  <TextField
                                    name={`previousAddress[${idx}].postCode`}
                                    value={values.previousAddress[idx].postCode}
                                    fullWidth
                                    onChange={handleChange}
                                    label="Post Code"
                                    placeholder="Post Code"
                                    size="small"
                                  />
                                </Box>
                                <Box
                                  mt={2}
                                  display={'flex'}
                                  justifyContent={
                                    idx + 1 === values.previousAddress.length
                                      ? 'space-between'
                                      : 'end'
                                  }
                                >
                                  <Button
                                    size="small"
                                    sx={{
                                      display:
                                        idx ===
                                        values.previousAddress.length - 1
                                          ? 'block'
                                          : 'none'
                                    }}
                                    variant="text"
                                    onClick={() =>
                                      push({
                                        ...previousAddressFields,
                                        id: values.previousAddress.length + 100
                                      })
                                    }
                                  >
                                    Add more
                                  </Button>
                                  <Button
                                    sx={{ display: idx === 0 && 'none' }}
                                    color="error"
                                    variant="text"
                                    size="small"
                                    onClick={(e) => remove(idx)}
                                  >
                                    Delete
                                  </Button>
                                </Box>
                              </Box>
                            </React.Fragment>
                          );
                        });
                      }}
                    </FieldArray>
                  </Grid>
                )}

                {/*  Lived / Chargesheets */}
                <Grid item xs={12}>
                  <Box
                    bgcolor={'#f8f8f8'}
                    display={'flex'}
                    flexDirection={'column'}
                    p={2}
                    gap={2}
                    borderRadius={1}
                  >
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={1}
                    >
                      <Typography>
                        Have you lived outside the UK for more than 6 MONTHSin
                        the past 5 YEARS?
                      </Typography>
                      <TextField
                        name="everLivedOutsideUK"
                        value={values.everLivedOutsideUK}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        Do you have any convictions, cautions, reprimands or
                        final warnings which would not be filtered in line with
                        current guidance?
                      </Typography>
                      <TextField
                        name="anyConvictions"
                        value={values.anyConvictions}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                  </Box>
                </Grid>

                {/* Consent */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={0} borderRadius={1}>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <List>
                        <ListItem>
                          <Typography fontSize={'small'} variant="subtitle2">
                            I hereby confirm that the information I have
                            provided in this application is complete and true. I
                            further understand that to knowingly provide false
                            information for this application is a criminal
                            offence.
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography fontSize={'small'} variant="subtitle2">
                            I have read, understood and accept the DDC Privacy
                            Policy. I give my consent for this information to be
                            passed to the Disclosure and Barring Service for the
                            purpose of applying for a Disclosure.
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography fontSize={'small'} variant="subtitle2">
                            I have read the Standard/Enhanced Check Privacy
                            Policy for applicants and I understand how DBS will
                            process my personal data and the options available
                            to me for submitting an application.
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography fontSize={'small'} variant="subtitle2">
                            I consent to the DBS providing an electronic result
                            directly to the registered body that has submitted
                            my application. I understand that an electronic
                            result contains a message that indicates either the
                            certificate is blank or to await certificate which
                            will indicate that my certificate contains
                            information. In some cases the registered body may
                            provide this information directly to my employer
                            prior to me receiving my certificate.
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography fontSize={'small'} variant="subtitle2">
                            I understand if I do not consent to an electronic
                            result being issued to the registered body
                            submitting my application that I must not proceed
                            with this application and I should submit a paper
                            application form.
                          </Typography>
                        </ListItem>
                        {/* <ListItem>
                          <Typography fontSize={'small'} variant="subtitle2">
                            I understand that to withdraw my consent whilst my
                            application is in progress I must contact the DBS
                            helpline 03000 200 190. My application will then be
                            withdrawn.
                          </Typography>
                        </ListItem> */}
                      </List>
                    </Box>
                  </Box>
                </Grid>

                {/* Changed Address */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        I have read and understood the above information, and
                        consent to the submission of the application
                      </Typography>
                      <TextField
                        name="consent"
                        value={values.consent}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleModalState('dbsModal', 'close')}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(AddressHistoryModal);
