import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import React from 'react';
import { RootState } from 'src/redux/store';
import { connect } from 'react-redux';
import FormStatus from '../FormStatus';
import EqualOpportunityMonitoring from './modals/EqualOpportunityMonitoring';
import NursingCompetencyChecklist from './modals/NursingCompetencyChecklist';

const ProfessionalInformation = ({ application }) => {
  const [modalOpen, setModalOpen] = React.useState({
    equalOpportunityMonitoringModal: false,
    nursingCompetencyChecklistModal: false
  });

  const handleModalState = (modal: string, changeType: 'open' | 'close') => {
    switch (modal) {
      case 'equalOpportunityMonitoringModal':
        setModalOpen((modalState) => ({
          ...modalState,
          equalOpportunityMonitoringModal: changeType === 'open' ? true : false
        }));
        break;

      case 'nursingCompetencyChecklistModal':
        setModalOpen((modalState) => ({
          ...modalState,
          nursingCompetencyChecklistModal: changeType === 'open' ? true : false
        }));
        break;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Equal Opportunity Monitoring
              </Typography>
              <FormStatus
                stage={application?.equalOpportunityMonitoring?.stage}
              />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Let us know about Equal Opportunity Monitoring
            </Typography>

            <Button
              onClick={() =>
                handleModalState('equalOpportunityMonitoringModal', 'open')
              }
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Nursing Competency Checklist (For Nurses Only)
              </Typography>
              <FormStatus
                stage={application?.nursingCompetencyChecklist?.stage}
              />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Let us know about Nursing Competency Checklist
            </Typography>

            <Button
              onClick={() =>
                handleModalState('nursingCompetencyChecklistModal', 'open')
              }
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      <EqualOpportunityMonitoring
        open={modalOpen.equalOpportunityMonitoringModal}
        handleModalState={handleModalState}
        formData={application.equalOpportunityMonitoring}
      />

      <NursingCompetencyChecklist
        open={modalOpen.nursingCompetencyChecklistModal}
        handleModalState={handleModalState}
        formData={application.nursingCompetencyChecklist}
      />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  application: state.applicationsReducer.currentApplication
}))(ProfessionalInformation);
