import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { login } from 'src/redux/actions/auth';
import { RootState } from 'src/redux/store';
import { toast } from 'react-toastify';
import { backend } from 'src/services/http';
import { register } from 'numeral';
import { useSelector } from 'react-redux';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © ' + new Date().getFullYear() + ' '}
      <a target="blank" href="https://mohsinyaqoob.com/">
        Better Software
      </a>{' '}
      {}
    </Typography>
  );
}

const registerUser = async (
  { firstName, lastName, email, password },
  resetForm
) => {
  try {
    const res = await backend.post('/forms/register', {
      firstName,
      lastName,
      email,
      password
    });
    return true;
    resetForm();
  } catch (error) {
    const errors: [] = error.response.data.errors;
    errors.forEach((error: any) => toast(error.msg, { type: 'error' }));
    return false;
  }
};

const Signup = () => {
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => ({
    state: state.authReducer
  }));

  if (!auth.state.loading && auth.state.loggedIn) {
    navigate('/dashboard');
  }

  const handleSubmit = async (values, resetForm) => {
    if (values.password !== values.confirm) {
      return toast('Passwords do not match', { type: 'error' });
    }
    const registered = await registerUser(values, resetForm);
    if (registered) {
      navigate(
        '/placeholder?message=Please check your email for a verification link'
      );
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1622253694238-3b22139576c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1965&q=80)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              confirm: ''
            }}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ values, handleChange, errors, touched }) => (
              <>
                <Form noValidate autoComplete="off">
                  <Box sx={{ mt: 1 }}>
                    <TextField
                      sx={{ fontSize: '0.6rem' }}
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      name="firstName"
                      label="First Name"
                      type="text"
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    <TextField
                      sx={{ fontSize: '0.6rem' }}
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      type="text"
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    <TextField
                      sx={{ fontSize: '0.6rem' }}
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      label="Email"
                      type="email"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <TextField
                      sx={{ fontSize: '0.6rem' }}
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    <TextField
                      sx={{ fontSize: '0.6rem' }}
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      name="confirm"
                      label="Confirm Password"
                      type="password"
                      onChange={handleChange}
                      value={values.confirm}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Sign In
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        {/* <Link to="#">Forgot password?</Link> */}
                      </Grid>
                      <Grid item>
                        <Link to="/login">
                          {'Already have an account? Log in'}
                        </Link>
                      </Grid>
                    </Grid>
                    <Copyright sx={{ mt: 5 }} />
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Signup;
