import { Container, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getApplications } from 'src/redux/actions/applications';
import { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import Applications from './Applications';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { backend } from 'src/services/http';

function Dashboard({ applications, loading, getApplications }) {
  useEffect(() => {
    getApplications();
  }, []);
  return (
    <>
      <Helmet>
        <title>Pulse Care - Applications</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg" sx={{ flex: 1 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item lg={12} xs={12}>
            {!loading && <Applications applications={applications} />}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default connect(
  (state: RootState) => ({
    applications: state.applicationsReducer.applications,
    loading: state.applicationsReducer.loading
  }),
  {
    getApplications
  }
)(Dashboard);
