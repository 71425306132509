import { CheckOutlined } from '@mui/icons-material';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import React from 'react';
import DBSModal from './modals/DBSModal';
import AddressHistoryModal from './modals/AddressHistoryModal';
import OverseasPoliceCheck from './modals/OverseasPoliceCheck';
import Declarations from './modals/Declarations';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import FormStatus from '../FormStatus';

const CriminalRecordCheck = ({ application }) => {
  const [modalOpen, setModalOpen] = React.useState({
    dbsModal: false,
    declarationsModal: false,
    addressHistoryModal: false,
    overseasPoliceCheckModal: false
  });

  const handleModalState = (
    modal:
      | 'dbsModal'
      | 'declarationsModal'
      | 'addressHistoryModal'
      | 'overseasPoliceCheckModal',
    changeType: 'open' | 'close'
  ) => {
    switch (modal) {
      case 'dbsModal':
        setModalOpen((modalState) => ({
          ...modalState,
          dbsModal: changeType === 'open' ? true : false
        }));
        break;

      case 'overseasPoliceCheckModal':
        setModalOpen((modalState) => ({
          ...modalState,
          overseasPoliceCheckModal: changeType === 'open' ? true : false
        }));
        break;

      case 'declarationsModal':
        setModalOpen((modalState) => ({
          ...modalState,
          declarationsModal: changeType === 'open' ? true : false
        }));
        break;

      case 'addressHistoryModal':
        setModalOpen((modalState) => ({
          ...modalState,
          addressHistoryModal: changeType === 'open' ? true : false
        }));
        break;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Disclosure and Barring Service (DBS)
              </Typography>
              <FormStatus stage={application?.dbs?.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Thorough background check for safer recruitment in sensitive
              roles.
            </Typography>

            <Button
              onClick={() => handleModalState('dbsModal', 'open')}
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/* <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Address History
              </Typography>
              <FormStatus stage={application?.addressHistory?.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Thorough background check for safer recruitment in sensitive
              roles.
            </Typography>

            <Button
              onClick={() => handleModalState('addressHistoryModal', 'open')}
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid> */}

      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Overseas Police Check
              </Typography>
              <FormStatus stage={application?.overseasPoliceCheck?.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Thorough background check for safer recruitment in sensitive
              roles.
            </Typography>

            <Button
              onClick={() =>
                handleModalState('overseasPoliceCheckModal', 'open')
              }
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Declarations
              </Typography>
              <FormStatus stage={application?.declarations?.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Thorough background check for safer recruitment in sensitive
              roles.
            </Typography>

            <Button
              onClick={() => handleModalState('declarationsModal', 'open')}
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/* Modals */}
      <DBSModal
        open={modalOpen.dbsModal}
        handleModalState={handleModalState}
        formData={application.dbs}
      />
      <AddressHistoryModal
        open={modalOpen.addressHistoryModal}
        handleModalState={handleModalState}
        formData={application.addressHistory}
      />
      <OverseasPoliceCheck
        open={modalOpen.overseasPoliceCheckModal}
        handleModalState={handleModalState}
        formData={application.overseasPoliceCheck}
      />
      <Declarations
        open={modalOpen.declarationsModal}
        handleModalState={handleModalState}
        formData={application.declarations}
      />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  application: state.applicationsReducer.currentApplication
}))(CriminalRecordCheck);
