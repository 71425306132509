import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { loadUser } from 'src/redux/actions/auth';
import store, { RootState } from 'src/redux/store';
import { backend } from 'src/services/http';

const PrivateRoute = ({ children, loggedIn, loading }) => {
  useEffect(() => {
    store.dispatch(loadUser() as any);
  }, []);

  const token = localStorage.getItem('formsAccessToken');
  if (token) {
    backend.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  if (!loggedIn && !loading) {
    return <Navigate to={`/login?redirectUrl=${location.pathname}`} />;
    // return <Navigate to={`/login`} />;
  }

  return <SidebarLayout>{children}</SidebarLayout>;
};

export default connect((state: RootState) => ({
  loggedIn: state.authReducer.loggedIn,
  loading: state.authReducer.loading
}))(PrivateRoute);
