import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Divider,
  Autocomplete,
  MenuItem,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { getSingleApplication } from 'src/redux/actions/applications';
import { backend } from 'src/services/http';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { useParams } from 'react-router-dom';
import { countryList } from 'src/data/countries';

type Props = {
  open: boolean;
  handleModalState: any;
  getSingleApplication;
  formData;
};

const submitEqualOpportunityMonitoring = async (values) => {
  try {
    await backend.post(
      '/forms/applications/equal-opportunity-monitoring',
      values
    );
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const EqualOpportunityMonitoring = ({
  open,
  handleModalState,
  getSingleApplication,
  formData
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() =>
        handleModalState('equalOpportunityMonitoringModal', 'close')
      }
    >
      <Formik
        initialValues={{
          gender: formData?.gender || 'Female',
          disable: formData?.disable || '',
          disability: formData?.disability || '',
          sexualOrientation: formData?.sexualOrientation || '',
          religion: formData?.religion || '',
          ethnicOrigin: formData?.ethnicOrigin || '',
          nationality: formData?.nationality || ''
        }}
        onSubmit={async (values) => {
          if (
            await submitEqualOpportunityMonitoring({ ...values, applicationId })
          ) {
            handleModalState('equalOpportunityMonitoringModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ setFieldValue, values, handleChange }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Equal Opportunity Monitoring</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="subtitle1">
                We are an equal opportunity employer and positively encourage
                applications from suitably qualified and eligible candidates
                regardless of sex, race, disability, age, sexual orientation, or
                religion or belief. To enable us to improve and monitor our
                employment processes, please complete the section below and note
                that this information is confidential and will be used only for
                the purpose of monitoring. Please tick the appropriate box
              </Typography>
              <Grid mt={2} container spacing={2}>
                <Grid xs={12} item>
                  <TextField
                    fullWidth
                    size="small"
                    name="gender"
                    value={values.gender}
                    placeholder="Gender"
                    label="Gender"
                    onChange={handleChange}
                    select
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Transgender">Transgender</MenuItem>
                    <MenuItem value="Undisclosed">Undisclosed</MenuItem>
                  </TextField>
                </Grid>

                <Grid xs={12} item>
                  <TextField
                    fullWidth
                    size="small"
                    name="disable"
                    value={values.disable}
                    placeholder="Do you consider yourself to be a disabled person?"
                    label="Do you consider yourself to be a disabled person?"
                    onChange={handleChange}
                    select
                  >
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="Undisclosed">Undisclosed</MenuItem>
                  </TextField>
                </Grid>

                {values.disable === 'Yes' && (
                  <Grid xs={12} item>
                    <TextField
                      fullWidth
                      size="small"
                      name="disability"
                      value={values.disability}
                      placeholder="Please give brief details of your disability"
                      label="Please give brief details of your disability"
                      onChange={handleChange}
                      multiline
                      minRows={3}
                    />
                  </Grid>
                )}

                <Grid xs={12} item>
                  <TextField
                    fullWidth
                    size="small"
                    name="sexualOrientation"
                    value={values.sexualOrientation}
                    placeholder="Sexual Orientation"
                    label="Sexual Orientation"
                    onChange={handleChange}
                    select
                  >
                    <MenuItem value="Bisexual">Bisexual</MenuItem>
                    <MenuItem value="Heterosexual">Heterosexual</MenuItem>
                    <MenuItem value="Homosexual">Homosexual</MenuItem>
                    <MenuItem value="Undisclosed">Undisclosed</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </TextField>
                </Grid>

                <Grid xs={12} item>
                  <TextField
                    fullWidth
                    size="small"
                    name="religion"
                    value={values.religion}
                    placeholder="Religion or Belief"
                    label="Religion or Belief"
                    onChange={handleChange}
                    select
                  >
                    <MenuItem value="Anglican">Anglican</MenuItem>
                    <MenuItem value="Catholic">Catholic</MenuItem>
                    <MenuItem value="Other Christian">Other Christian</MenuItem>
                    <MenuItem value="Protestant">Protestant</MenuItem>
                    <MenuItem value="Budhist">Budhist</MenuItem>
                    <MenuItem value="Jewish">Jewish</MenuItem>
                    <MenuItem value="Muslim">Muslim</MenuItem>
                    <MenuItem value="Sikh">Sikh</MenuItem>
                    <MenuItem value="Hindu">Hindu</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </TextField>
                </Grid>

                <Grid xs={12} item>
                  <TextField
                    fullWidth
                    size="small"
                    name="ethnicOrigin"
                    value={values.ethnicOrigin}
                    placeholder="Ethnic Origin"
                    label="Ethnic Origin"
                    onChange={handleChange}
                    select
                  >
                    <MenuItem value="White / English">White English</MenuItem>
                    <MenuItem value="White / Scotish">White / Scotish</MenuItem>
                    <MenuItem value="White / Welish">White / Welish</MenuItem>
                    <MenuItem value="White / Irish">White / Irish</MenuItem>
                    <MenuItem value="White / Other">White / Other</MenuItem>
                    <MenuItem value="Mixed / White & Black Caribbean">
                      Mixed / White & Black Caribbean
                    </MenuItem>
                    <MenuItem value="Mixed / White & Black African">
                      Mixed / White & Black African
                    </MenuItem>
                    <MenuItem value="Mixed / White & Asian">
                      Mixed / White & Asian
                    </MenuItem>
                    <MenuItem value="Mixed / Other">Mixed / Other</MenuItem>
                    <MenuItem value="Asian / Indian">Asian / Indian</MenuItem>
                    <MenuItem value="Asian / Pakistani">
                      Asian / Pakistani
                    </MenuItem>
                    <MenuItem value="Asian / Bangladeshi">
                      Asian / Bangladeshi
                    </MenuItem>
                    <MenuItem value="Asian / Other">Asian / Other</MenuItem>
                    <MenuItem value="Black / Caribbean">
                      Black / Caribbean
                    </MenuItem>
                    <MenuItem value="Black / African">Black / African</MenuItem>
                    <MenuItem value="Black / Other">Black / Other</MenuItem>
                    <MenuItem value="Chinese / Chinese">
                      Chinese / Chinese
                    </MenuItem>
                    <MenuItem value="Chinese / Other">Chinese / Other</MenuItem>

                    <MenuItem value="Other">Other</MenuItem>
                    <MenuItem value="Prefer not to answer">
                      Prefer not to answer
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid xs={12} item>
                  <Autocomplete
                    size="small"
                    fullWidth
                    id="nationality"
                    options={countryList}
                    value={values.nationality}
                    onChange={(_, values) =>
                      setFieldValue('nationality', values)
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ fontSize: '0.6rem' }}
                        size="small"
                        {...params}
                        variant="outlined"
                        label="Nationality"
                        placeholder="Nationality"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  handleModalState('equalOpportunityMonitoringModal', 'close')
                }
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(
  EqualOpportunityMonitoring
);
