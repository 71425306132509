export const competencies = [
  'A & E',
  'General',
  'Mental Health',
  'Radiology',
  'Anesthetic Trained',
  'Dental',
  'Midwifery',
  'Recovery',
  'Autism',
  'Gynecology',
  'Neonatal',
  'Renal',
  'Cardiac',
  'Hematology',
  'Neurology',
  'Residential Homes',
  'Cardiothoracic',
  'HDU',
  'Nursing Homes',
  'Respite Care',
  'Care of the Elderly',
  'Health Visitor',
  'Occupational Health',
  'SCBU',
  'Challenging Behavior',
  'Home Care',
  'ODP',
  'School Nurse',
  'Chemotherapy',
  'Ni-Hospices',
  'Oncology',
  'Senior Care',
  'Clinics',
  'Hospitals',
  'Ophthalmology',
  'Social Care',
  'CSSD',
  'ITU',
  'Orthopedics',
  'Social Worker',
  'Community',
  'ITU Psychiatric',
  'Palliative Care',
  'Support Worker',
  'District Nursing',
  'In Charge Wards',
  'Practice Nurse',
  'Surgical',
  'Day care centers/hospitals',
  'In Charge Nursing Homes',
  'Plastic Surgery',
  'Terminal Care',
  'Diabetic Care',
  'In Charge Residential Homes',
  'Pediatrics',
  'Training',
  'EMI',
  'Learning Disability',
  'PICU',
  'Theatre',
  'Eating Disorders',
  'Medical',
  'Prisons',
  'Urology'
];
export const experiences = [
  'Anesthetics',
  'Insertion of Laryngeal airway',
  'Acute Behavioural Problems',
  "PCA's & Calibration",
  'CSSD',
  'IV Cannulation',
  'Anaphylactic shock',
  'Running in theatre',
  'A&E Minor Injuries',
  'Ability to Plaster',
  'Baby Immunisation',
  'Baxter pumps',
  'Blood OBS & charting',
  'Boots Monitoring Drug System',
  'Care Plans/ Assessment',
  'Cassette Drug System',
  'Catheterisation M/F',
  'Control & Restraint',
  'CVP Readings',
  'Dental',
  'Dinomaps',
  'Drug Rounds/Medication',
  'Eating Disorders',
  'ECT Treatment',
  'Emis Computer System',
  'Escort Duty (Blue light)',
  'Flowtrons',
  'Forensic Medicine',
  'Gemini Pumps',
  "Graseby's Pumps",
  'Ileostomy Care',
  'Nara Gastric feeding',
  'Oncology Drugs',
  'Outpatients Clinic',
  'Passing Naso-Gastric Tubes',
  'PCA inc Settings/Checks',
  'Peg feeds',
  'Pressure air care',
  "Recording & Charting of BM's",
  'Revival Care',
  'Removal of CVP Line',
  'Resuscitation A&E',
  'Sliding scale/Reporting',
  'Smear Tests',
  'Stoma Care',
  'Suture & Clip Removal',
  'Syringe Drivers',
  'Tracheotomy Care',
  'Thyroidectomy Care',
  'Use of most Pumps on market',
  'Ventilated Patients'
];
