import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import FileUpload from 'src/components/FileUpload';
import { deleteFile, uploadFile } from 'src/services';
import { useParams } from 'react-router';
import { renderHttpErrors } from 'src/helpers/toast';
import { useState } from 'react';
import { connect } from 'react-redux';
import { getSingleApplication } from 'src/redux/actions/applications';
import { toast } from 'react-toastify';
import { backend } from 'src/services/http';
import React from 'react';

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const submitDocuments = async (values) => {
  try {
    await backend.post('/forms/applications/documents', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const DocumentsModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  const [fileUploadLoading, setFileUploadLoading] = useState({
    passport: false,
    brp: false,
    brpBack: false,
    proofOfId: false,
    proofOfIdBack: false,
    proofOfAddress1: false,
    proofOfAddress2: false,
    cvResume: false,
    elevenModulesTraining: false,
    ivAdminCertificate: false,
    nmcStatement: false,
    trainingCertificate: false,
    oldDbsCopy: false,
    immunizationHistory: false,
    qualificationCertificate: false,
    nimProof: false,
    idBadge: false,
    // DOCTORS
    gmcRegistrationEvidence: false,
    lifeSupportTrainingEvidence: false,
    medicalIndemnityCoverEvidence: false,
    annualAppraisalEvidence: false,
    covidVaccinationOneEvidence: false,
    covidVaccinationTwoEvidence: false,
    additionalDocument: false
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [fileToDelete, setFileToDelete] = useState({
    fileType: '',
    key: ''
  });

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleFileUpload = async (file, type: any) => {
    try {
      setFileUploadLoading({ ...fileUploadLoading, [type]: true });
      const data: any = await uploadFile(file, type, applicationId);
      setFileUploadLoading({ ...fileUploadLoading, [type]: false });
      return data.data;
    } catch (error) {
      renderHttpErrors(error);
      setFileUploadLoading({ ...fileUploadLoading, [type]: false });
    }
  };

  const handleFileDelete = async (setFieldValue) => {
    const { fileType, key } = fileToDelete;
    setFieldValue(fileType, undefined);
    if (await deleteFile(fileType, applicationId, key)) {
      toast('Document deleted', { type: 'success' });
      setDeleteDialogOpen(false);
    }
  };

  const handleDownload = () => {
    console.log('Attempting to download the file..');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        handleModalState('documentsModal', 'close');
        getSingleApplication(applicationId);
      }}
    >
      <Formik
        initialValues={{
          passport: formData?.passport || undefined,
          brp: formData?.brp || undefined,
          brpBack: formData?.brpBack || undefined,
          proofOfId: formData?.proofOfId || undefined,
          proofOfIdBack: formData?.proofOfIdBack || undefined,
          proofOfAddress1: formData?.proofOfAddress1 || undefined,
          proofOfAddress2: formData?.proofOfAddress2 || undefined,
          cvResume: formData?.cvResume || undefined,
          trainingCertificate: formData?.trainingCertificate || undefined,
          // elevenModulesTraining: formData?.elevenModulesTraining || undefined,
          ivAdminCertificate: formData?.ivAdminCertificate || undefined,
          nmcStatement: formData?.nmcStatement || undefined,
          oldDbsCopy: formData?.oldDbsCopy || undefined,
          immunizationHistory: formData?.immunizationHistory || undefined,
          qualificationCertificate:
            formData?.qualificationCertificate || undefined,
          nimProof: formData?.nimProof || undefined,
          idBadge: formData?.idBadge || undefined,
          // DOCTORS
          gmcRegistrationEvidence:
            formData?.gmcRegistrationEvidence || undefined,
          lifeSupportTrainingEvidence:
            formData?.lifeSupportTrainingEvidence || undefined,
          medicalIndemnityCoverEvidence:
            formData?.medicalIndemnityCoverEvidence || undefined,
          annualAppraisalEvidence:
            formData?.annualAppraisalEvidence || undefined,
          covidVaccinationOneEvidence:
            formData?.covidVaccinationOneEvidence || undefined,
          covidVaccinationTwoEvidence:
            formData?.covidVaccinationTwoEvidence || undefined,
          additionalDocument: formData?.additionalDocument || undefined
        }}
        onSubmit={async (values) => {
          if (await submitDocuments({ ...values, applicationId })) {
            handleModalState('documentsModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <Form noValidate autoComplete="off">
              <DialogTitle>DBS</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Fields marked with (*) are mandatory and cannot be left blank
                </DialogContentText>
                <DialogContentText color={'error'}>
                  NOTE: Allowed file types are JPG, PNG or PDF only.
                </DialogContentText>
                <DialogContentText color={'error'}>
                  Max file size: 5MB
                </DialogContentText>
                <Grid container mt={2} spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={4}
                      // bgcolor={'#f8f8f8'}
                      // p={2}
                      borderRadius={1}
                    >
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>Passport</Typography>
                        <Typography variant="caption">
                          Share a picture/scan copy with all four corners
                          visible of the passport. Make sure no objects(
                          hands/fingers) are covering the parts of the document.
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.passport}
                          viewOnly={values.passport ? true : false}
                          link={values.passport ? values.passport : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'passport'
                            );
                            uploadedFile &&
                              setFieldValue('passport', uploadedFile.data.file);
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'passport',
                              key: values.passport
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          BRP Front Side{' '}
                          <span style={{ color: 'red' }}>
                            (For Non-british candidates only)
                          </span>
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.brp}
                          viewOnly={values.brp ? true : false}
                          link={values.brp ? values.brp : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'brp'
                            );
                            uploadedFile &&
                              setFieldValue('brp', uploadedFile.data.file);
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'brp',
                              key: values.brp
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          BRP Back Side{' '}
                          <span style={{ color: 'red' }}>
                            (For Non-british candidates only)
                          </span>
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.brpBack}
                          viewOnly={values.brpBack ? true : false}
                          link={values.brpBack ? values.brpBack : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'brpBack'
                            );
                            uploadedFile &&
                              setFieldValue('brpBack', uploadedFile.data.file);
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'brpBack',
                              key: values.brpBack
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Proof of ID Front Side: Example UK Driving License
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.proofOfId}
                          viewOnly={values.proofOfId ? true : false}
                          link={values.proofOfId ? values.proofOfId : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'proofOfId'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'proofOfId',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'proofOfId',
                              key: values.proofOfId
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Proof of ID Back Side: Example UK Driving License
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.proofOfIdBack}
                          viewOnly={values.proofOfIdBack ? true : false}
                          link={
                            values.proofOfIdBack ? values.proofOfIdBack : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'proofOfIdBack'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'proofOfIdBack',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'proofOfIdBack',
                              key: values.proofOfIdBack
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Upload 2 proofs of address: example, bank
                          statement(less than 3 months old), utility bill(less
                          than 12 months old), tax bill(less than 12 months
                          old), P60 (less than 12 months old)
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.proofOfAddress1}
                          viewOnly={values.proofOfAddress1 ? true : false}
                          link={
                            values.proofOfAddress1 ? values.proofOfAddress1 : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'proofOfAddress1'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'proofOfAddress1',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'proofOfAddress1',
                              key: values.proofOfAddress1
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.proofOfAddress2}
                          viewOnly={values.proofOfAddress2 ? true : false}
                          link={
                            values.proofOfAddress2 ? values.proofOfAddress2 : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'proofOfAddress2'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'proofOfAddress2',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'proofOfAddress2',
                              key: values.proofOfAddress2
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>CV/Resume</Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.cvResume}
                          viewOnly={values.cvResume ? true : false}
                          link={values.cvResume ? values.cvResume : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'cvResume'
                            );
                            uploadedFile &&
                              setFieldValue('cvResume', uploadedFile.data.file);
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'cvResume',
                              key: values.cvResume
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          IV administration certificate ( Applicable to nurses
                          only). If you don't have one, you can request us to
                          book it. It will be chargeable
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.ivAdminCertificate}
                          viewOnly={values.ivAdminCertificate ? true : false}
                          link={
                            values.ivAdminCertificate
                              ? values.ivAdminCertificate
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'ivAdminCertificate'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'ivAdminCertificate',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'ivAdminCertificate',
                              key: values.ivAdminCertificate
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>NMC statement( For nurses only)</Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.nmcStatement}
                          viewOnly={values.nmcStatement ? true : false}
                          link={values.nmcStatement ? values.nmcStatement : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'nmcStatement'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'nmcStatement',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'nmcStatement',
                              key: values.nmcStatement
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Trainings: (For Healthcare Assistants, a care
                          certificate is required or NVQ level 3) and 11 modules
                          of Mandatory Trainings are required for all healthcare
                          professionals. If you don't possess one, we can book
                          it for you. It will be chargeable
                        </Typography>
                        <Box width="full">
                          <FileUpload
                            handleClick={handleDownload}
                            loading={fileUploadLoading.trainingCertificate}
                            viewOnly={values.trainingCertificate ? true : false}
                            link={
                              values.trainingCertificate
                                ? values.trainingCertificate
                                : ''
                            }
                            handleFileChange={async (file) => {
                              const uploadedFile = await handleFileUpload(
                                file,
                                'trainingCertificate'
                              );
                              uploadedFile &&
                                setFieldValue(
                                  'trainingCertificate',
                                  uploadedFile.data.file
                                );
                            }}
                            handleFileDelete={() => {
                              setFileToDelete({
                                fileType: 'trainingCertificate',
                                key: values.trainingCertificate
                              });
                              handleDeleteDialogOpen();
                            }}
                          />
                        </Box>
                      </Box>
                      {/* <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Trainings :(For Healthcare Assistants, a care
                          certificate is required or NVQ level 3) and 11 modules
                          of Mandatory Trainings are required for all healthcare
                          professionals. If you don't possess one, we can book
                          it for you. It will be chargeable
                        </Typography>
                        <FileUpload
                            handleClick={handleDownload}
                          loading={fileUploadLoading.elevenModulesTraining}
                          viewOnly={values.elevenModulesTraining ? true : false}
                          link={
                            values.elevenModulesTraining
                              ? values.elevenModulesTraining
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'elevenModulesTraining'
                            );
                            setFieldValue(
                              'elevenModulesTraining',
                              uploadedFile.data.file
                            );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'elevenModulesTraining',
                              key: values.elevenModulesTraining
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box> */}
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          OLD DBS Copy (DBS must be on update service). If not,
                          then we will have to process a new DBS. It will be
                          chargeable
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.oldDbsCopy}
                          viewOnly={values.oldDbsCopy ? true : false}
                          link={values.oldDbsCopy ? values.oldDbsCopy : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'oldDbsCopy'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'oldDbsCopy',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'oldDbsCopy',
                              key: values.oldDbsCopy
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Immunisation history from the GP. If you don't have
                          one, you can visit your Gp, or we can book your
                          appointment with a private health provider. We can
                          help with that. It will be chargeable.
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.immunizationHistory}
                          viewOnly={values.immunizationHistory ? true : false}
                          link={
                            values.immunizationHistory
                              ? values.immunizationHistory
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'immunizationHistory'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'immunizationHistory',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'immunizationHistory',
                              key: values.immunizationHistory
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Qualifications: example, degree certificate, NVQ
                          certificate, or any other certification
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.qualificationCertificate}
                          viewOnly={
                            values.qualificationCertificate ? true : false
                          }
                          link={
                            values.qualificationCertificate
                              ? values.qualificationCertificate
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'qualificationCertificate'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'qualificationCertificate',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'qualificationCertificate',
                              key: values.qualificationCertificate
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Please upload a scan or photo of your National
                          Insurance card/ P45/ P60/ HMRC letter/ DWP letter as
                          proof of your National Insurance number (please note
                          that payslips are not accepted as proof)
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.nimProof}
                          viewOnly={values.nimProof ? true : false}
                          link={values.nimProof ? values.nimProof : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'nimProof'
                            );
                            uploadedFile &&
                              setFieldValue('nimProof', uploadedFile.data.file);
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'nimProof',
                              key: values.nimProof
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Please upload a colour passport size photo for your ID
                          badge
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.idBadge}
                          viewOnly={values.idBadge ? true : false}
                          link={values.idBadge ? values.idBadge : ''}
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'idBadge'
                            );
                            uploadedFile &&
                              setFieldValue('idBadge', uploadedFile.data.file);
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'idBadge',
                              key: values.idBadge
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          GMC Registration Evidence (For Doctors only)
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.gmcRegistrationEvidence}
                          viewOnly={
                            values.gmcRegistrationEvidence ? true : false
                          }
                          link={
                            values.gmcRegistrationEvidence
                              ? values.gmcRegistrationEvidence
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'gmcRegistrationEvidence'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'gmcRegistrationEvidence',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'gmcRegistrationEvidence',
                              key: values.gmcRegistrationEvidence
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Life Support Training (BLS) Evidence
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={
                            fileUploadLoading.lifeSupportTrainingEvidence
                          }
                          viewOnly={
                            values.lifeSupportTrainingEvidence ? true : false
                          }
                          link={
                            values.lifeSupportTrainingEvidence
                              ? values.lifeSupportTrainingEvidence
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'lifeSupportTrainingEvidence'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'lifeSupportTrainingEvidence',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'lifeSupportTrainingEvidence',
                              key: values.lifeSupportTrainingEvidence
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          Medical Indemnity Cover Evidence
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={
                            fileUploadLoading.medicalIndemnityCoverEvidence
                          }
                          viewOnly={
                            values.medicalIndemnityCoverEvidence ? true : false
                          }
                          link={
                            values.medicalIndemnityCoverEvidence
                              ? values.medicalIndemnityCoverEvidence
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'medicalIndemnityCoverEvidence'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'medicalIndemnityCoverEvidence',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'medicalIndemnityCoverEvidence',
                              key: values.medicalIndemnityCoverEvidence
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>Annual Appraisal Evidence</Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.annualAppraisalEvidence}
                          viewOnly={
                            values.annualAppraisalEvidence ? true : false
                          }
                          link={
                            values.annualAppraisalEvidence
                              ? values.annualAppraisalEvidence
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'annualAppraisalEvidence'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'annualAppraisalEvidence',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'annualAppraisalEvidence',
                              key: values.annualAppraisalEvidence
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          COVID Vaccination 1 Evidence (Req Evidence NHS App,
                          NHS Website, NHS COVID Pass Letter) If medically
                          exempt, please upload exemption.
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={
                            fileUploadLoading.covidVaccinationOneEvidence
                          }
                          viewOnly={
                            values.covidVaccinationOneEvidence ? true : false
                          }
                          link={
                            values.covidVaccinationOneEvidence
                              ? values.covidVaccinationOneEvidence
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'covidVaccinationOneEvidence'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'covidVaccinationOneEvidence',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'covidVaccinationOneEvidence',
                              key: values.covidVaccinationOneEvidence
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>
                          COVID Vaccination 2 Evidence (Req Evidence NHS App,
                          NHS Website, NHS COVID Pass Letter) If medically
                          exempt, please upload exemption
                        </Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={
                            fileUploadLoading.covidVaccinationTwoEvidence
                          }
                          viewOnly={
                            values.covidVaccinationTwoEvidence ? true : false
                          }
                          link={
                            values.covidVaccinationTwoEvidence
                              ? values.covidVaccinationTwoEvidence
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'covidVaccinationTwoEvidence'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'covidVaccinationTwoEvidence',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'covidVaccinationTwoEvidence',
                              key: values.covidVaccinationTwoEvidence
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>

                      <Box
                        className="innerInputContaine"
                        p={2}
                        bgcolor={'#f8f8f8'}
                        borderRadius={1}
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography>Additional document (if any)</Typography>
                        <FileUpload
                          handleClick={handleDownload}
                          loading={fileUploadLoading.additionalDocument}
                          viewOnly={values.additionalDocument ? true : false}
                          link={
                            values.additionalDocument
                              ? values.additionalDocument
                              : ''
                          }
                          handleFileChange={async (file) => {
                            const uploadedFile = await handleFileUpload(
                              file,
                              'additionalDocument'
                            );
                            uploadedFile &&
                              setFieldValue(
                                'additionalDocument',
                                uploadedFile.data.file
                              );
                          }}
                          handleFileDelete={() => {
                            setFileToDelete({
                              fileType: 'additionalDocument',
                              key: values.additionalDocument
                            });
                            handleDeleteDialogOpen();
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleModalState('documentsModal', 'close');
                    getSingleApplication(applicationId);
                  }}
                >
                  Close
                </Button>
                {/* <Button type="submit">Save</Button> */}
              </DialogActions>
            </Form>
            <Dialog
              open={deleteDialogOpen}
              onClose={handleDeleteDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Confirm Deletion
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this document / attachment?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                <Button
                  color="error"
                  onClick={() => handleFileDelete(setFieldValue)}
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(DocumentsModal);
