export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT = 'LOGOUT';

export const APPLICATIONS_FETCH_SUCCESS = 'APPLICATIONS_FETCH_SUCCESS';
export const APPLICATION_SINGLE_LOADING = 'APPLICATION_SINGLE_LOADING';
export const APPLICATIONS_FETCH_FAILED = 'APPLICATIONS_FETCH_FAILED';
export const APPLICATION_SINGLE_FETCH_SUCCESS =
  'APPLICATION_SINGLE_FETCH_SUCCESS';
