import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, Divider } from '@mui/material';
import { FieldArray, Form, Formik, getIn, useFormik } from 'formik';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getSingleApplication } from 'src/redux/actions/applications';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { useParams } from 'react-router-dom';

type Props = {
  open: boolean;
  handleModalState: any;
  getSingleApplication;
  formData;
};

const referAFriendFields = {
  fullName: '',
  jobTitle: '',
  mobile: '',
  email: '',
  location: ''
};

const submitReferrals = async (values) => {
  try {
    await backend.post('/forms/applications/refer-friend', {
      applicationId: values.applicationId,
      referrals: values.friends
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const ReferAFriendModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('referAFriendModal', 'close')}
    >
      <Formik
        initialValues={{
          friends:
            formData.referrals && formData.referrals.length
              ? formData.referrals.map((referral) => ({
                  email: referral.email,
                  fullName: referral.fullName,
                  jobTitle: referral.jobTitle,
                  location: referral.location,
                  mobile: referral.mobile
                }))
              : [referAFriendFields]
        }}
        onSubmit={async (values) => {
          if (await submitReferrals({ ...values, applicationId })) {
            handleModalState('referAFriendModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Refer a Friend</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="caption">
                Refer a friend & earn £££
              </Typography>

              <FieldArray name="friends">
                {({ push, remove }) => (
                  <div>
                    {values.friends.map((friend, index) => {
                      return (
                        <Grid key={index} mt={2} container spacing={2}>
                          <Grid
                            // sx={{ display: index === 0 && 'none' }}
                            item
                            xs={12}
                          >
                            <Typography variant="subtitle2">
                              {index + 1} - Enter your friend's details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="fullName"
                              name={`friends[${index}].fullName`}
                              label="First Name"
                              fullWidth
                              variant="outlined"
                              value={friend.fullName}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="jobTitle"
                              name={`friends[${index}].jobTitle`}
                              label="Job Title"
                              fullWidth
                              variant="outlined"
                              value={friend.jobTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="Mobile"
                              name={`friends[${index}].mobile`}
                              label="Mobile"
                              fullWidth
                              variant="outlined"
                              value={friend.mobile}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="Email"
                              name={`friends[${index}].email`}
                              label="Email"
                              fullWidth
                              variant="outlined"
                              value={friend.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="Location"
                              name={`friends[${index}].location`}
                              label="Location"
                              fullWidth
                              variant="outlined"
                              value={friend.location}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              size="small"
                              sx={{
                                display:
                                  index === values.friends.length - 1
                                    ? 'block'
                                    : 'none'
                              }}
                              variant="outlined"
                              onClick={() => push(referAFriendFields)}
                            >
                              Add more
                            </Button>
                          </Grid>
                          <Grid
                            alignContent={'end'}
                            // sx={{ display: index === 0 && 'none' }}
                            item
                            xs={6}
                            textAlign={'right'}
                          >
                            <Button
                              sx={{ display: index === 0 && 'none' }}
                              size="small"
                              onClick={() => remove(index)}
                              variant="contained"
                              color="error"
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </div>
                )}
              </FieldArray>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleModalState('referAFriendModal', 'close')}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(ReferAFriendModal);
