import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, Divider, MenuItem, Box } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import { getSingleApplication } from 'src/redux/actions/applications';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import FileUpload from 'src/components/FileUpload';
import { useState } from 'react';
import { deleteFile, uploadFile } from 'src/services';
import React from 'react';

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const professionalReferencesFields = {
  dateFrom: '',
  dateTo: '',
  title: '',
  fullName: '',
  jobTitle: '',
  department: '',
  company: '',
  address1: '',
  address2: '',
  city: '',
  postCode: '',
  telephone: '',
  email: '',
  stamp: ''
};

const submitProfessionalReferences = async (values) => {
  try {
    await backend.post('/forms/applications/professional-references', {
      references: values.forms,
      applicationId: values.applicationId
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const ProfessionalReferences = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const [fileUploadLoading, setFileUploadLoading] = useState({
    stamp: false
  });
  const [fileToDelete, setFileToDelete] = useState({
    fileType: '',
    key: ''
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const params = useParams();
  const applicationId = params.id;

  const handleFileUpload = async (file, type: any) => {
    try {
      setFileUploadLoading({ ...fileUploadLoading, [type]: true });
      const data: any = await uploadFile(file, type, applicationId);
      setFileUploadLoading({ ...fileUploadLoading, [type]: false });
      return data.data;
    } catch (error) {
      renderHttpErrors(error);
      setFileUploadLoading({ ...fileUploadLoading, [type]: false });
    }
  };

  const handleFileDelete = async (setFieldValue) => {
    debugger;
    const { fileType, key } = fileToDelete;
    setFieldValue(fileType, undefined);
    if (await deleteFile(fileType, applicationId, key)) {
      toast('Document deleted', { type: 'success' });
      setDeleteDialogOpen(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('professionalReferencesModal', 'close')}
    >
      <Formik
        initialValues={{
          forms:
            formData?.references && formData?.references?.length > 0
              ? formData?.references?.map((reference) => ({
                  dateFrom: new Date(reference.dateFrom) || '',
                  dateTo: new Date(reference.dateTo) || '',
                  title: reference.title || '',
                  fullName: reference.fullName || '',
                  jobTitle: reference.jobTitle || '',
                  department: reference.department || '',
                  company: reference.company || '',
                  address1: reference.address1 || '',
                  address2: reference.address2 || '',
                  city: reference.city || '',
                  postCode: reference.postCode || '',
                  telephone: reference.telephone || '',
                  email: reference.email || '',
                  stamp: reference.stamp || undefined
                }))
              : [professionalReferencesFields, professionalReferencesFields]
        }}
        onSubmit={async (values) => {
          if (
            await submitProfessionalReferences({ ...values, applicationId })
          ) {
            handleModalState('professionalReferencesModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <Form noValidate autoComplete="off">
              <DialogTitle>Professional References</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Fields marked with (*) are mandatory and cannot be left blank
                </DialogContentText>
                <Typography variant="subtitle2">
                  Please provide one - two professional references from your
                  last employers. Starting with your most recent job. By
                  professional we mean actual employers not colleagues therefore
                  work email addresses are essential. All references must relate
                  to employment over the past 3 years
                </Typography>
                <Typography variant="subtitle2">
                  By providing these details, you give us your consent to
                  contact the below referees
                </Typography>
                <FieldArray name="forms">
                  {({ push, remove }) => (
                    <div>
                      {values.forms.map((value, index) => {
                        return (
                          <Grid mt={2} key={index + 1} container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="subtitle2">
                                {index + 1} - Enter your reference details
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                label="Referee Working Start Date"
                                onChange={(value) =>
                                  setFieldValue(
                                    `forms[${index}].dateFrom`,
                                    value
                                  )
                                }
                                value={value.dateFrom}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                label="Referee Working End Date"
                                onChange={(value) =>
                                  setFieldValue(`forms[${index}].dateTo`, value)
                                }
                                value={value.dateTo}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                select
                                id="title"
                                name={`forms[${index}].title`}
                                label="Referee Title"
                                fullWidth
                                variant="outlined"
                                value={value.title}
                                onChange={handleChange}
                              >
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                                <MenuItem value="Dr">Dr</MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="fullName"
                                name={`forms[${index}].fullName`}
                                label="Referee Full Name"
                                fullWidth
                                variant="outlined"
                                value={value.fullName}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="jobTitle"
                                name={`forms[${index}].jobTitle`}
                                label="Referee Job Title"
                                fullWidth
                                variant="outlined"
                                value={value.jobTitle}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="department"
                                name={`forms[${index}].department`}
                                label="Referee Department"
                                fullWidth
                                variant="outlined"
                                value={value.department}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="company"
                                name={`forms[${index}].company`}
                                label="Referee Company Name"
                                fullWidth
                                variant="outlined"
                                value={value.company}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="address1"
                                name={`forms[${index}].address1`}
                                label="Referee Address Line 1"
                                fullWidth
                                variant="outlined"
                                value={value.address1}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="address2"
                                name={`forms[${index}].address2`}
                                label="Referee Address Line 2"
                                fullWidth
                                variant="outlined"
                                value={value.address2}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="city"
                                name={`forms[${index}].city`}
                                label="Referee City"
                                fullWidth
                                variant="outlined"
                                value={value.city}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="postCode"
                                name={`forms[${index}].postCode`}
                                label="Referee Post Code"
                                fullWidth
                                variant="outlined"
                                value={value.postCode}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="telephone"
                                name={`forms[${index}].telephone`}
                                label="Referee Telephone"
                                fullWidth
                                variant="outlined"
                                value={value.telephone}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color={'error'}>
                                Only business/work emails are accepted. For
                                example john@company.com, john@nhs.net
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                id="email"
                                name={`forms[${index}].email`}
                                label="Referee Email"
                                fullWidth
                                variant="outlined"
                                value={value.email}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {/* Stamp Upload */}
                              {/* <Box
                                className="innerInputContaine"
                                p={2}
                                bgcolor={'#f8f8f8'}
                                borderRadius={1}
                                display="flex"
                                flexDirection="column"
                                gap={1}
                              >
                                <Typography>Stamp</Typography>
                                <Typography variant="caption">
                                  Upload a stamp obtained from your reference
                                </Typography>
                                <FileUpload
                                  loading={fileUploadLoading.stamp}
                                  viewOnly={value.stamp ? true : false}
                                  link={value.stamp ? value.stamp : ''}
                                  handleFileChange={async (file) => {
                                    const uploadedFile = await handleFileUpload(
                                      file,
                                      `stamp|${index}`
                                    );
                                    setFieldValue(
                                      `forms.${index}.stamp`,
                                      uploadedFile.data.file
                                    );
                                  }}
                                  handleFileDelete={() => {
                                    setFileToDelete({
                                      fileType: `stamp|${index}`,
                                      key: value.stamp
                                    });
                                    setDeleteDialogOpen(true);
                                  }}
                                />
                              </Box> */}
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                size="small"
                                sx={{
                                  display:
                                    index === values.forms.length - 1
                                      ? 'block'
                                      : 'none'
                                }}
                                variant="outlined"
                                onClick={() =>
                                  push(professionalReferencesFields)
                                }
                              >
                                Add more
                              </Button>
                            </Grid>
                            <Grid
                              alignContent={'end'}
                              item
                              xs={6}
                              textAlign={'right'}
                            >
                              <Button
                                sx={{ display: index < 2 && 'none' }}
                                onClick={() => remove(index)}
                                variant="contained"
                                color="error"
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </div>
                  )}
                </FieldArray>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() =>
                    handleModalState('professionalReferencesModal', 'close')
                  }
                >
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </DialogActions>
            </Form>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Confirm Deletion
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this document / attachment?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialogOpen(false)}>
                  Cancel
                </Button>
                <Button
                  color="error"
                  onClick={() => handleFileDelete(setFieldValue)}
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(ProfessionalReferences);
