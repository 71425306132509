import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import { CircularProgress, DialogActions, Typography } from '@mui/material';
import {
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip
} from '@mui/material';

import PersonalInformation from './Personal';
import PreferencesAndAvailability from './Preferences';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import RightToWork from './RightToWork';
import PaymentInfo from './PaymentInfo';
import WorkHistoryAndReferences from './WorkHistoryAndReferences';
import HealthChecklist from './HealthChecklist';
import { toast } from 'react-toastify';
import CriminalRecordCheck from './CriminalRecordCheck';
import Documents from './Documents';
import DeclerationAndConsent from './Declaration';
import { finalSubmitForm } from 'src/services';
import { getApplications } from 'src/redux/actions/applications';
import { connect } from 'react-redux';
import { getStepStatusColor } from 'src/utils';
import { formSteps } from 'src/constants';
import ProfessionalInformation from './Professional';
import { RootState } from 'src/redux/store';

const steps = [
  {
    id: 1,
    label: formSteps.PERSONAL_INFORMATION,
    description: 'This is the basic information about yourself'
  },
  {
    id: 2,
    label: formSteps.PREFERENCES_AND_AVAILABILITY,
    description: 'This is the Contact information about yourself'
  },
  {
    id: 3,
    label: formSteps.RIGHT_TO_WORK,
    description: 'This is the DBS information about yourself'
  },
  {
    id: 4,
    label: formSteps.PAYMENT_INFO,
    description: 'This is the Ref. information about yourself'
  },
  {
    id: 5,
    label: formSteps.WORK_HISTORY_AND_REFERENCES,
    description: 'This is the Misc. information about yourself'
  },
  {
    id: 6,
    label: formSteps.FITNESS_TO_WORK,
    description: 'This is the Misc. information about yourself'
  },
  {
    id: 7,
    label: formSteps.CRIMINAL_RECORD_CHECK,
    description: 'This is the Misc. information about yourself'
  },
  {
    id: 8,
    label: formSteps.PROFESSIONAL_INFORMATION,
    description: 'This is the Misc. information about yourself'
  },
  {
    id: 9,
    label: formSteps.DOCUMENTS,
    description: 'This is the Misc. information about yourself'
  },
  {
    id: 10,
    label: formSteps.EMPLOYEE_DECLARATION,
    description: 'This is the Misc. information about yourself'
  }
];

const ApplicationForm = ({ getApplications, currentApplicationLoading }) => {
  const [dialogOpen, setDialogOpen] = React.useState({
    confirmPrompt: false,
    progressPrompt: false
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const step = +searchParams.get('step');
  const params = useParams();

  const applicationId = params.id;
  const [activeStep, setActiveStep] = React.useState(step);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  React.useEffect(() => {
    if (activeStep > 9) {
      setActiveStep(0);
    }
    navigate(`?step=${activeStep}`);
  }, [activeStep]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleFinalSubmit = async () => {
    // Check if anything is pending
    const submissionSuccess = await finalSubmitForm(applicationId);
    if (submissionSuccess) {
      toast('Submission successfull', { type: 'success' });
      await getApplications();
      navigate('/dashboard');
    }
    setDialogOpen({ confirmPrompt: false, progressPrompt: false });
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const getForm = (step) => {
    switch (step) {
      case 0:
        return <PersonalInformation />;

      case 1:
        return <PreferencesAndAvailability />;

      case 2:
        return <RightToWork />;

      case 3:
        return <PaymentInfo />;

      case 4:
        return <WorkHistoryAndReferences />;

      case 5:
        return <HealthChecklist />;

      case 6:
        return <CriminalRecordCheck />;

      case 7:
        return <ProfessionalInformation />;

      case 8:
        return <Documents />;

      case 9:
        return <DeclerationAndConsent />;

      default:
      // return <BasicInfo />;
    }
  };

  // if (currentApplicationLoading) {
  //   return <Typography>Loading...</Typography>;
  // }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{
          padding: 2,
          borderRadius: 2,
          overflow: 'scroll',
          overflowY: 'hidden'
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <Tooltip arrow title={step.label} placement="top-start">
              <StepButton
                sx={{
                  textAlign: 'left',
                  '& .MuiStepLabel-iconContainer .MuiStepIcon-root circle': {
                    fill: getStepStatusColor(step) as any
                  }
                }}
                color="inherit"
                onClick={handleStep(index)}
                focusRipple
              >
                <Typography fontSize={12}>{step.label}</Typography>
              </StepButton>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        <Box mt={4}>
          {/*  */}
          <Container maxWidth="sm">{getForm(activeStep)}</Container>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pt: 2,
            padding: 2,
            borderRadius: 2
          }}
        >
          <Button
            size="small"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button size="small" onClick={handleNext} sx={{ mr: 1 }}>
            Next
          </Button>
          <Button
            size="small"
            onClick={() =>
              setDialogOpen({ ...dialogOpen, confirmPrompt: true })
            }
          >
            Final Submit
          </Button>
        </Box>
      </React.Fragment>
      <Dialog
        open={dialogOpen.confirmPrompt}
        onClose={() => setDialogOpen({ ...dialogOpen, confirmPrompt: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Final Submit your application?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to final submit your application. This step
            cannot be undone. Do you still want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            autoFocus
            onClick={() =>
              setDialogOpen({ ...dialogOpen, confirmPrompt: false })
            }
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={() => {
              setDialogOpen({
                ...dialogOpen,
                confirmPrompt: false,
                progressPrompt: true
              });
              handleFinalSubmit();
            }}
            autoFocus
          >
            Yes, Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogOpen.progressPrompt}
        onClose={() => setDialogOpen({ ...dialogOpen, progressPrompt: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Submitting your application
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <CircularProgress size={32} />
            </Box>
          </DialogContent>
          <DialogContentText>
            Please wait while we submit your application. Do not refresh or
            close this page.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    currentApplicationLoading:
      state.applicationsReducer.currentApplicationLoading
  }),
  { getApplications }
)(ApplicationForm);
