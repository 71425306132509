import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { backend } from 'src/services/http';

type Props = {};

const verifyToken = async (email, verificationToken) => {
  try {
    await backend.post('/forms/verify-account', {
      email,
      verificationToken
    });
    return true;
  } catch (error) {
    const errors = error.response.data.errors;
    errors.forEach((error) => toast(error.msg, { type: 'error' }));
    return false;
  }
};

const VerifyAccount = (props: Props) => {
  const { email, verificationToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function verify() {
      const verified = await verifyToken(email, verificationToken);
      if (verified) {
        toast('Email has been verified successfully. Please login', {
          type: 'success',
          autoClose: false
        });
        navigate('/login');
      } else {
        toast(
          'Failed to verify email. Please make sure you have the correct verification link',
          { type: 'success', autoClose: false }
        );
        navigate('/login');
      }
    }

    verify();
  }, []);

  return <></>;
};

export default VerifyAccount;
