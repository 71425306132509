import { WarningOutlined } from '@mui/icons-material';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import React from 'react';
import DeclarationsAndConsentModal from './modals/DeclarationsModal';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import FormStatus from '../FormStatus';

const DeclerationAndConsent = ({ application }) => {
  const [modalOpen, setModalOpen] = React.useState({
    declarationsAndConsentModal: false
  });

  const handleModalState = (
    modal: 'declarationsAndConsentModal',
    changeType: 'open' | 'close'
  ) => {
    switch (modal) {
      case 'declarationsAndConsentModal':
        setModalOpen((modalState) => ({
          ...modalState,
          declarationsAndConsentModal: changeType === 'open' ? true : false
        }));
        break;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Declaration
              </Typography>
              <FormStatus stage={application?.declarationsAndConsent?.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Declaration and Consent
            </Typography>

            <Button
              onClick={() =>
                handleModalState('declarationsAndConsentModal', 'open')
              }
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      <DeclarationsAndConsentModal
        open={modalOpen.declarationsAndConsentModal}
        handleModalState={handleModalState}
        formData={application.declarationsAndConsent}
      />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  application: state.applicationsReducer.currentApplication
}))(DeclerationAndConsent);
