import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Typography,
  Divider,
  MenuItem,
  Box,
  Checkbox,
  IconButton,
  Tooltip,
  Collapse,
  Autocomplete
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { InfoOutlined } from '@mui/icons-material';
import FileInput from 'src/components/FileUploader';
import { DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import { getSingleApplication } from 'src/redux/actions/applications';
import { RootState } from 'src/redux/store';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { useState } from 'react';
import DateSelector from 'src/components/DateSelector';
import { countryList } from 'src/data/countries';

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const submitHealthChecklist = async (values) => {
  try {
    await backend.post('/forms/applications/health-checklist', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const HealthCheck = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  const [collapse, setCollapse] = useState({ titleText: true });
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('healthCheckModal', 'close')}
    >
      <Formik
        initialValues={{
          personalInformation: {
            title: formData?.personalInformation?.title || '',
            surname: formData?.personalInformation?.surname || '',
            firstname: formData?.personalInformation?.firstname || '',
            dob: new Date(formData?.personalInformation?.dob) || '',
            homeTel: formData?.personalInformation?.homeTel || '',
            workTel: formData?.personalInformation?.workTel || '',
            mobile: formData?.personalInformation?.mobile || '',
            homeAddress: formData?.personalInformation?.homeAddress || '',
            gpAddress: formData?.personalInformation?.gpAddress || ''
          },
          medicalHistory: {
            doYouHaveAnyIllness:
              formData?.medicalHistory?.doYouHaveAnyIllness || 'No',
            haveYouHadAnyIllness:
              formData?.medicalHistory?.haveYouHadAnyIllness || 'No',
            awaitingTreatment:
              formData?.medicalHistory?.awaitingTreatment || 'No',
            assistanceNeededToPerformJob:
              formData?.medicalHistory?.assistanceNeededToPerformJob || 'No',
            sufferedFromMrsa:
              formData?.medicalHistory?.sufferedFromMrsa || 'No',
            mrsaDate:
              new Date(formData?.medicalHistory?.mrsaDate) || new Date(),
            sufferedFromCDiff:
              formData?.medicalHistory?.sufferedFromCDiff || 'No',
            cdiffDate:
              new Date(formData?.medicalHistory?.cdiffDate) || new Date(),
            mrsaAndCDiffDetails:
              formData?.medicalHistory?.mrsaAndCDiffDetails || '',
            everHadChickenPoxOrShingles:
              formData?.medicalHistory?.everHadChickenPoxOrShingles || 'No',
            chickenPoxDate:
              new Date(formData?.medicalHistory?.chickenPoxDate) || '',
            everHadContactWithBbvs:
              formData?.medicalHistory?.everHadContactWithBbvs || 'No',

            // TB 1
            livedOutsideUk: formData?.medicalHistory?.livedOutsideUk || 'No',
            detailsAboutPastTravel:
              formData?.medicalHistory?.detailsAboutPastTravel || [],
            everHadBcgVaccine:
              formData?.medicalHistory?.everHadBcgVaccine || 'No',
            bcgVaccineDate:
              new Date(formData?.medicalHistory?.bcgVaccineDate) || '',

            // TB 2
            coughForMoreThanThreeWeeks:
              formData?.medicalHistory?.coughForMoreThanThreeWeeks || 'No',
            unexplanedWeightLoss:
              formData?.medicalHistory?.unexplanedWeightLoss || 'No',
            unexplainedFever:
              formData?.medicalHistory?.unexplainedFever || 'No',
            everHadTb: formData?.medicalHistory?.everHadTb || 'No',
            tbDetails: formData?.medicalHistory?.tbDetails || '',

            // IMMUNIZATION 1
            hadTrippleVaccination:
              formData?.medicalHistory?.hadTrippleVaccination || 'No',
            trippleVaccinationDate:
              new Date(formData?.medicalHistory?.trippleVaccinationDate) || '',
            hadPolioVaccination:
              formData?.medicalHistory?.hadPolioVaccination || 'No',
            polioVaccinationDate:
              new Date(formData?.medicalHistory?.polioVaccinationDate) || '',
            hadTetanus: formData?.medicalHistory?.hadTetanus || 'No',
            tetanusDate: new Date(formData?.medicalHistory?.tetanusDate) || '',
            hepatitisB: formData?.medicalHistory?.hepatitisB || 'No',
            hepatitisVaccineDates: {
              course:
                formData?.medicalHistory?.hepatitisVaccineDates.course.map(
                  (date) => new Date(date)
                ),
              boosters:
                formData?.medicalHistory?.hepatitisVaccineDates.boosters.map(
                  (date) => new Date(date)
                )
            },
            willYourRoleInvolveExposureProneProcedures:
              formData?.medicalHistory
                ?.willYourRoleInvolveExposureProneProcedures || 'No'
          },
          consent: {
            consentForReportsBeingAssessed:
              formData?.consent?.consentForReportsBeingAssessed || 'No',
            consentToOurOccupationalHealthAdvisorsSpeakingWithYou:
              formData?.consent
                ?.consentToOurOccupationalHealthAdvisorsSpeakingWithYou || 'No',
            consentToOurOccupationalHealthAdvisorsMakingRecommendations:
              formData?.consent
                ?.consentToOurOccupationalHealthAdvisorsMakingRecommendations ||
              'No'
          },
          iDeclareAllAnswersAreCorrect:
            formData?.iDeclareAllAnswersAreCorrect || 'No'
        }}
        onSubmit={async (values) => {
          if (await submitHealthChecklist({ ...values, applicationId })) {
            handleModalState('healthCheckModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="OFF">
            <DialogTitle>Health Checklist</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography>
                Due to the nature of the role you have applied for we need to
                carry out an assessment of a new starter health questionnaire –
                even if you have been employed in UK health services before.
              </Typography>
              <Collapse in={!collapse.titleText} timeout="auto" unmountOnExit>
                <Typography>
                  The health of each candidate is considered individually and a
                  decision regarding fitness for work in the prospective job
                  role will be based on the functional effects of any underlying
                  health condition/disability/impairment as well as health
                  service requirements for fitness and immune status. Before
                  health clearance is given for employment you may be contacted
                  by telephone from a clinician at Healthier Business UK Ltd,
                  however you may also need to be seen by an occupational health
                  advisor/specialist or physician, arrangements for face to face
                  consultations will be arranged by your employer or agency. We
                  may recommend adjustments or assistance following an
                  assessment to enable you to carry out your proposed duties
                  safely and effectively. Recommendations to your employer will
                  be directed to essential information regarding your health and
                  the hazards and risks of your employment and with due
                  reference to other relevant statutory requirements and
                  professional practice. Our aim is to promote and maintain the
                  health of all individuals in the workplace: staff, service
                  users and third parties. Your records will be retained
                  electronically in accordance with best practice and the
                  requirements of the General Data Protection Regulations. Your
                  records will be held on file for the purposes of processing
                  your request only and for no longer than is necessary, however
                  your records may be subject to internal clinical audits. Your
                  records may also be used to cross reference and ascertain your
                  fitness should you register with other clients of Healthier
                  Business UK Ltd
                </Typography>
              </Collapse>
              <Typography
                onClick={() => setCollapse({ titleText: !collapse.titleText })}
                color={'primary'}
              >
                {collapse.titleText === true ? 'Read more' : 'Read less'}
              </Typography>
              <Grid container spacing={2} mt={2}>
                {/* Personal Information */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Typography variant="body1" fontWeight={'bold'} mb={1}>
                      Personal Information
                    </Typography>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <TextField
                        label="Title"
                        placeholder="Title"
                        name="personalInformation.title"
                        value={values.personalInformation.title}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                        select
                      >
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mrs">Mrs</MenuItem>
                        <MenuItem value="Miss">Miss</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                        <MenuItem value="Dr">Dr</MenuItem>
                      </TextField>
                      <TextField
                        label="Sur Name"
                        placeholder="Sur Name"
                        name="personalInformation.surname"
                        value={values.personalInformation.surname}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                      />
                      <TextField
                        label="First Name"
                        placeholder="First Name"
                        name="personalInformation.firstname"
                        value={values.personalInformation.firstname}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                      />
                      <DateSelector
                        handleChange={(date) =>
                          setFieldValue('personalInformation.dob', date)
                        }
                        label="Date of Birth"
                        value={values.personalInformation.dob}
                      />
                      <TextField
                        label="Home Telephone"
                        placeholder="Home Telephone"
                        name="personalInformation.homeTel"
                        value={values.personalInformation.homeTel}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                      />
                      <TextField
                        label="Work Telephone"
                        placeholder="Work Telephone"
                        name="personalInformation.workTel"
                        value={values.personalInformation.workTel}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                      />
                      <TextField
                        label="Mobile Number"
                        placeholder="Mobile Number"
                        name="personalInformation.mobile"
                        value={values.personalInformation.mobile}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                      />
                      <TextField
                        multiline
                        rows={3}
                        label="Home Address"
                        placeholder="Home Address"
                        name="personalInformation.homeAddress"
                        value={values.personalInformation.homeAddress}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                      />
                      <TextField
                        multiline
                        rows={3}
                        label="GP Address"
                        placeholder="GP Address"
                        name="personalInformation.gpAddress"
                        value={values.personalInformation.gpAddress}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* Medical Histry */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Typography variant="body1" fontWeight={'bold'} mb={1}>
                      Medical Information
                    </Typography>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          Do you have any illness/impairment/disability
                          (physical or psychological) which may affect your
                          work?
                        </Typography>
                        <TextField
                          // label="doYouHaveAnyIllness"
                          // placeholder="doYouHaveAnyIllness"
                          name="medicalHistory.doYouHaveAnyIllness"
                          value={values.medicalHistory.doYouHaveAnyIllness}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">
                          Have you ever had any illness/impairment/disability
                          which may have been caused or made worse by your work?
                        </Typography>
                        <TextField
                          name="medicalHistory.haveYouHadAnyIllness"
                          value={values.medicalHistory.haveYouHadAnyIllness}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">
                          Are you having, or waiting for treatment (including
                          medication) or investigations at present?
                        </Typography>
                        <TextField
                          name="medicalHistory.awaitingTreatment"
                          value={values.medicalHistory.awaitingTreatment}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">
                          Do you think you may need any adjustments or
                          assistance to help you to do the job?
                        </Typography>
                        <TextField
                          name="medicalHistory.assistanceNeededToPerformJob"
                          value={
                            values.medicalHistory.assistanceNeededToPerformJob
                          }
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">
                          Have you ever suffered from Methicillin Resistant
                          Staphylococcus Aureus (MRSA)
                        </Typography>
                        <TextField
                          name="medicalHistory.sufferedFromMrsa"
                          value={values.medicalHistory.sufferedFromMrsa}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {/* MRSA Date */}
                      {values.medicalHistory.sufferedFromMrsa === 'Yes' && (
                        <Box>
                          <Typography variant="subtitle1">
                            Date of MRSA
                          </Typography>
                          <DateSelector
                            handleChange={(date) =>
                              setFieldValue('medicalHistory.mrsaDate', date)
                            }
                            value={values.medicalHistory.mrsaDate}
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography variant="subtitle1">
                          Have you ever suffered from Clostridium Difficile
                          (C-Diff)
                        </Typography>
                        <TextField
                          name="medicalHistory.sufferedFromCDiff"
                          value={values.medicalHistory.sufferedFromCDiff}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.sufferedFromCDiff === 'Yes' && (
                        <Box>
                          <Typography variant="subtitle1">
                            Date of CDIFF
                          </Typography>
                          <DateSelector
                            handleChange={(date) =>
                              setFieldValue('medicalHistory.cdiffDate', date)
                            }
                            value={values.medicalHistory.cdiffDate}
                          />
                        </Box>
                      )}
                      {values.medicalHistory.sufferedFromCDiff === 'Yes' ||
                      values.medicalHistory.sufferedFromMrsa === 'Yes' ? (
                        <Box>
                          <Typography variant="subtitle1">
                            Please provide additional information about MRSA /
                            CDIFF below, including dates, treatment and details
                            of condition
                          </Typography>
                          <TextField
                            multiline
                            rows={3}
                            name="medicalHistory.mrsaAndCDiffDetails"
                            value={values.medicalHistory.mrsaAndCDiffDetails}
                            fullWidth
                            size="small"
                            onChange={handleChange}
                          />
                        </Box>
                      ) : (
                        ''
                      )}
                      <Box>
                        <Typography variant="subtitle1">
                          Have you ever had chicken pox or shingles?
                        </Typography>
                        <TextField
                          name="medicalHistory.everHadChickenPoxOrShingles"
                          value={
                            values.medicalHistory.everHadChickenPoxOrShingles
                          }
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.everHadChickenPoxOrShingles ===
                        'Yes' && (
                        <Box>
                          <Typography variant="subtitle1">
                            Specify Date for chicken pox or shingles?
                          </Typography>
                          <DateSelector
                            handleChange={(date) =>
                              setFieldValue(
                                'medicalHistory.chickenPoxDate',
                                date
                              )
                            }
                            value={values.medicalHistory.chickenPoxDate}
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography variant="subtitle1">
                          Have you ever come into contact with any BBV’s?
                          Including Needle Stick Injuries?
                        </Typography>
                        <TextField
                          name="medicalHistory.everHadContactWithBbvs"
                          value={values.medicalHistory.everHadContactWithBbvs}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* TB Details */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Typography variant="body1" fontWeight={'bold'} mb={1}>
                      Tuberculosis
                    </Typography>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          Have you lived outside the UK or had an extended
                          holiday outside the UK in the last year?
                        </Typography>
                        <TextField
                          name="medicalHistory.livedOutsideUk"
                          value={values.medicalHistory.livedOutsideUk}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.livedOutsideUk === 'Yes' && (
                        <Box>
                          <Typography variant="subtitle1">
                            Please list all the countries that you have lived
                            in/visited over the last year, including holidays
                            and vacations. This MUST include duration of stay
                            and dates or this form will be rejected.
                          </Typography>
                          <Autocomplete
                            size="small"
                            fullWidth
                            multiple={true}
                            id="detailsAboutPastTravel"
                            options={countryList}
                            value={values.medicalHistory.detailsAboutPastTravel}
                            onChange={(_, values) =>
                              setFieldValue(
                                'medicalHistory.detailsAboutPastTravel',
                                values
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                sx={{ fontSize: '0.6rem' }}
                                size="small"
                                {...params}
                                variant="outlined"
                              />
                            )}
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography variant="subtitle1">
                          Have you had a BCG vaccination in relation to
                          Tuberculosis?
                        </Typography>
                        <TextField
                          name="medicalHistory.everHadBcgVaccine"
                          value={values.medicalHistory.everHadBcgVaccine}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.everHadBcgVaccine === 'Yes' && (
                        <Box>
                          <Typography variant="subtitle1">
                            Date of BCG Vaccination
                          </Typography>
                          <DateSelector
                            handleChange={(date) =>
                              setFieldValue(
                                'medicalHistory.bcgVaccineDate',
                                date
                              )
                            }
                            value={values.medicalHistory.bcgVaccineDate}
                          />
                        </Box>
                      )}

                      <Box>
                        <Typography variant="subtitle1">
                          Have you hads a cough which has lasted for more than 3
                          weeks
                        </Typography>
                        <TextField
                          name="medicalHistory.coughForMoreThanThreeWeeks"
                          value={
                            values.medicalHistory.coughForMoreThanThreeWeeks
                          }
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>

                      <Box>
                        <Typography variant="subtitle1">
                          Have you suffered any unexplained weight loss
                        </Typography>
                        <TextField
                          name="medicalHistory.unexplanedWeightLoss"
                          value={values.medicalHistory.unexplanedWeightLoss}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>

                      <Box>
                        <Typography variant="subtitle1">
                          Have you suffered from an unexplained fever
                        </Typography>
                        <TextField
                          name="medicalHistory.unexplainedFever"
                          value={values.medicalHistory.unexplainedFever}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">
                          Have you had tuberculosis (TB) or been in recent
                          contact with open TB
                        </Typography>
                        <TextField
                          name="medicalHistory.everHadTb"
                          value={values.medicalHistory.everHadTb}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.everHadTb === 'Yes' ||
                      values.medicalHistory.unexplainedFever === 'Yes' ||
                      values.medicalHistory.unexplanedWeightLoss === 'Yes' ||
                      values.medicalHistory.coughForMoreThanThreeWeeks ===
                        'Yes' ? (
                        <Box>
                          <Typography variant="subtitle1">
                            Give additional details about TB
                          </Typography>
                          <TextField
                            placeholder="Additional Details about TB"
                            name="medicalHistory.tbDetails"
                            value={values.medicalHistory.tbDetails}
                            fullWidth
                            size="small"
                            onChange={handleChange}
                            multiline
                            rows={2}
                          />
                        </Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                </Grid>

                {/* Immunization History */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Typography variant="body1" fontWeight={'bold'} mb={1}>
                      Immunization History
                    </Typography>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          Have you ever had triple vaccination as a child
                          (Diptheria / Tetanus / Whooping cough)
                        </Typography>
                        <TextField
                          name="medicalHistory.hadTrippleVaccination"
                          value={values.medicalHistory.hadTrippleVaccination}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.hadTrippleVaccination ===
                        'Yes' && (
                        <Box>
                          <Typography variant="subtitle1">
                            Date of Tripple Vaccination
                          </Typography>
                          <DateSelector
                            handleChange={(date) =>
                              setFieldValue(
                                'medicalHistory.trippleVaccinationDate',
                                date
                              )
                            }
                            label=""
                            value={values.medicalHistory.trippleVaccinationDate}
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography variant="subtitle1">
                          Have you ever had immunizations for Polio?
                        </Typography>
                        <TextField
                          name="medicalHistory.hadPolioVaccination"
                          value={values.medicalHistory.hadPolioVaccination}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.hadPolioVaccination === 'Yes' && (
                        <Box>
                          <Typography variant="subtitle1">
                            Date of Immunizations for Polio
                          </Typography>
                          <DateSelector
                            handleChange={(date) =>
                              setFieldValue(
                                'medicalHistory.polioVaccinationDate',
                                date
                              )
                            }
                            label=""
                            value={values.medicalHistory.polioVaccinationDate}
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography variant="subtitle1">
                          Have you ever had Tetanus?
                        </Typography>
                        <TextField
                          name="medicalHistory.hadTetanus"
                          value={values.medicalHistory.hadTetanus}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.hadTetanus === 'Yes' && (
                        <Box>
                          <Typography variant="subtitle1">
                            Date of Tetanus
                          </Typography>
                          <DateSelector
                            handleChange={(date) =>
                              setFieldValue('medicalHistory.tetanusDate', date)
                            }
                            label=""
                            value={values.medicalHistory.tetanusDate}
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography variant="subtitle1">
                          Have you recieved immunizations for Heptitis B?
                        </Typography>
                        <TextField
                          name="medicalHistory.hepatitisB"
                          value={values.medicalHistory.hepatitisB}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      {values.medicalHistory.hepatitisB === 'Yes' && (
                        <Box display={'flex'} gap={2}>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            flex={1}
                            gap={1}
                          >
                            <Box>
                              <Typography variant="subtitle1">
                                #1 Course Date (Hepatitis B)
                              </Typography>
                              <DateSelector
                                handleChange={(date) =>
                                  setFieldValue(
                                    'medicalHistory.hepatitisVaccineDates.course[0]',
                                    date
                                  )
                                }
                                label=""
                                value={
                                  values.medicalHistory.hepatitisVaccineDates[0]
                                }
                              />
                            </Box>
                            <Box>
                              <Typography variant="subtitle1">
                                #2 Course Date (Hepatitis B)
                              </Typography>
                              <DateSelector
                                handleChange={(date) =>
                                  setFieldValue(
                                    'medicalHistory.hepatitisVaccineDates.course[1]',
                                    date
                                  )
                                }
                                label=""
                                value={
                                  values.medicalHistory.hepatitisVaccineDates[1]
                                }
                              />
                            </Box>
                            <Box>
                              <Typography variant="subtitle1">
                                #3 Course Date (Hepatitis B)
                              </Typography>
                              <DateSelector
                                handleChange={(date) =>
                                  setFieldValue(
                                    'medicalHistory.hepatitisVaccineDates.course[2]',
                                    date
                                  )
                                }
                                label=""
                                value={
                                  values.medicalHistory.hepatitisVaccineDates[2]
                                }
                              />
                            </Box>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            flex={1}
                            gap={1}
                          >
                            <Box>
                              <Typography variant="subtitle1">
                                #1 Booster Date (Hepatitis B)
                              </Typography>
                              <DateSelector
                                handleChange={(date) =>
                                  setFieldValue(
                                    'medicalHistory.hepatitisVaccineDates.boosters[0]',
                                    date
                                  )
                                }
                                label=""
                                value={
                                  values.medicalHistory.hepatitisVaccineDates[0]
                                }
                              />
                            </Box>
                            <Box>
                              <Typography variant="subtitle1">
                                #2 Booster Date (Hepatitis B)
                              </Typography>
                              <DateSelector
                                handleChange={(date) =>
                                  setFieldValue(
                                    'medicalHistory.hepatitisVaccineDates.boosters[1]',
                                    date
                                  )
                                }
                                label=""
                                value={
                                  values.medicalHistory.hepatitisVaccineDates[1]
                                }
                              />
                            </Box>
                            <Box>
                              <Typography variant="subtitle1">
                                #3 Booster Date (Hepatitis B)
                              </Typography>
                              <DateSelector
                                handleChange={(date) =>
                                  setFieldValue(
                                    'medicalHistory.hepatitisVaccineDates.boosters[2]',
                                    date
                                  )
                                }
                                label=""
                                value={
                                  values.medicalHistory.hepatitisVaccineDates[2]
                                }
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>

                {/* Exposure Prone Procedures */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Typography variant="body1" fontWeight={'bold'} mb={1}>
                      Exposure Prone Procedures
                    </Typography>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          Will your role involve Exposure Prone Procedures?
                        </Typography>
                        <TextField
                          name="medicalHistory.willYourRoleInvolveExposureProneProcedures"
                          value={
                            values.medicalHistory
                              .willYourRoleInvolveExposureProneProcedures
                          }
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                        <Typography mt={1}>
                          EPPs include procedures where the worker’s gloved
                          hands may be in contact with sharp instruments, needle
                          tips or sharp tissues inside a patient’s open body
                          cavity, wound or confined anatomical space, where the
                          hands or fingertips may not be completely visible at
                          all times. However other situations, such as
                          pre-hospital trauma care, should be avoided by HCWs
                          restricted from performing EPPs, as they could also
                          result in the exposure of the patient’s open tissues
                          to the blood of the worker
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* GENERAL PROTECTION LAW COPY
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="small"
                    color="primary"
                  >
                    UK General Data Protection Regulation (UK GDPR)
                  </Button>
                </Grid> */}

                {/* Consent */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Typography variant="body1" fontWeight={'bold'} mb={1}>
                      Consent
                    </Typography>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          Do you consent to this questionnaire and your
                          immunisation reports being assessed by an Occupational
                          Health Advisor for the purpose of providing a Fitness
                          to Work Certificate?
                        </Typography>
                        <TextField
                          name="consent.consentForReportsBeingAssessed"
                          value={values.consent.consentForReportsBeingAssessed}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">
                          Do you consent to our Occupational Health Advisors
                          speaking with you regarding any declaration you may
                          have made relating to your medical history?
                        </Typography>
                        <TextField
                          name="consent.consentToOurOccupationalHealthAdvisorsSpeakingWithYou"
                          value={
                            values.consent
                              .consentToOurOccupationalHealthAdvisorsSpeakingWithYou
                          }
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">
                          Do you consent to our Occupational Health Advisors
                          making recommendations to your employer/agency to
                          assist with your ability to carry out your prospective
                          role?
                        </Typography>
                        <TextField
                          name="consent.consentToOurOccupationalHealthAdvisorsMakingRecommendations"
                          value={
                            values.consent
                              .consentToOurOccupationalHealthAdvisorsMakingRecommendations
                          }
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* Declaration */}
                <Grid item xs={12}>
                  <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                    <Typography variant="body1" fontWeight={'bold'} mb={1}>
                      Declaration
                    </Typography>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          I will inform my employer if I am planning to or leave
                          the UK for longer than a three-month period to enable
                          a reassessment of my health to be conducted on my
                          return. I declare that the answers to the above
                          questions are true and complete to the best of my
                          knowledge and belief.
                        </Typography>
                        <TextField
                          name="iDeclareAllAnswersAreCorrect"
                          value={values.iDeclareAllAnswersAreCorrect}
                          fullWidth
                          size="small"
                          onChange={handleChange}
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleModalState('healthCheckModal', 'close')}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(HealthCheck);
