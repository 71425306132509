import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { getSingleApplication } from 'src/redux/actions/applications';
import { backend } from 'src/services/http';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import DateRangePicker from 'src/components/DateRangeSelector';
import { format } from 'date-fns';

type Props = {
  open: boolean;
  handleModalState: any;
  getSingleApplication;
  formData;
};

const submitAvailability = async (values) => {
  try {
    await backend.post('/forms/applications/availability', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const AvailabilityModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  const [dateRangeOpen, setDateRangeOpen] = React.useState(false);
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('availabilityModal', 'close')}
    >
      <Formik
        initialValues={{
          joiningDate: formData?.joiningDate
            ? new Date(formData?.joiningDate)
            : new Date(),
          interviewDates:
            formData.interviewFrom && formData.interviewTo
              ? `${formData.interviewFrom} - ${formData.interviewTo}`
              : `Select dates`,
          availableForDayNightShifts: formData.availableForDayNightShifts || ''
        }}
        onSubmit={async (values) => {
          if (await submitAvailability({ ...values, applicationId })) {
            handleModalState('availabilityModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <DialogTitle>Availability</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Grid mt={2} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    When would you be available for joining?
                  </Typography>
                  <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    sx={{ width: '100%' }}
                    onChange={(value) => setFieldValue('joiningDate', value)}
                    value={values.joiningDate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    When are you available for Interviews?
                  </Typography>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="interviewDates"
                    name="interviewDates"
                    label=""
                    value={values.interviewDates}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      readOnly: true
                    }}
                    onClick={() => setDateRangeOpen(true)}
                    placeholder="Interview Availability"
                  />
                  <DateRangePicker
                    title={'Choose Interview Availability Dates'}
                    open={dateRangeOpen}
                    setOpen={setDateRangeOpen}
                    handleChange={(startDate, endDate) => {
                      setFieldValue(
                        'interviewDates',
                        `${format(startDate, 'dd/MM/yyyy')} - ${format(
                          endDate,
                          'dd/MM/yyyy'
                        )}`
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} mt={1}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    select
                    id="availableForDayNightShifts"
                    name="availableForDayNightShifts"
                    label="Are you availible for both day and night shifts?"
                    value={values.availableForDayNightShifts}
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItem value={'Yes'}>Yes</MenuItem>
                    <MenuItem value={'No'}>No</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleModalState('availabilityModal', 'close')}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(AvailabilityModal);
