import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

type Props = {};

const Placeholder = (props: Props) => {
  const [searchParams] = useSearchParams();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100vh'}
    >
      <Typography variant="h5">
        {searchParams.get('message') || 'This is a placeholder page 😇'}
      </Typography>
    </Box>
  );
};

export default Placeholder;
