import { CheckOutlined } from '@mui/icons-material';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Avatar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PersonalInformationModal from './modals/BasicInformation';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import FormStatus from '../FormStatus';

type Props = {
  user?;
  application?;
};

const PersonalInformation = ({ user, application }: Props) => {
  const { personalInformation } = application;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Basic Information
              </Typography>
              <FormStatus stage={application.personalInformation.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Information about yourself, contact details and mailing addresses
            </Typography>

            <Button
              onClick={() => setOpen(true)}
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/* Modals */}
      <PersonalInformationModal
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        formData={personalInformation}
      />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  user: state.authReducer.user,
  application: state.applicationsReducer.currentApplication
}))(PersonalInformation);
