import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useSearchParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { login } from 'src/redux/actions/auth';
import { RootState } from 'src/redux/store';
import { useLinkClickHandler } from 'react-router-dom';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © ' + new Date().getFullYear() + ' '}
      <a target="blank" href="https://mohsinyaqoob.com/">
        Better Software
      </a>{' '}
      {}
    </Typography>
  );
}

const Login = ({ login, loading, loggedIn }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  const handleSubmit = ({ username, password }) => {
    login(username, password);
  };

  React.useEffect(() => {
    if (!loading && loggedIn) {
      navigate(redirectUrl || '/dashboard');
    }
  }, [loading, loggedIn]);

  return (
    !loading && (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url(https://images.unsplash.com/photo-1579684385127-1ef15d508118?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1780&q=80)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Formik
              initialValues={{
                username: '',
                password: ''
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                  <Box sx={{ mt: 1 }}>
                    <TextField
                      sx={{ fontSize: '0.6rem' }}
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="username"
                      autoFocus
                      onChange={handleChange}
                      value={values.username}
                    />
                    <TextField
                      sx={{ fontSize: '0.6rem' }}
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      onChange={handleChange}
                      value={values.password}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Sign In
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link to="/reset-password">Forgot password?</Link>
                      </Grid>
                      <Grid item>
                        <Link to="/signup">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>
                    <Copyright sx={{ mt: 5 }} />
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    )
  );
};

export default connect(
  (state: RootState) => ({
    loading: state.authReducer.loading,
    loggedIn: state.authReducer.loggedIn
  }),
  {
    login
  }
)(Login);
