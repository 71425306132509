import { format } from 'date-fns';
import { formSteps } from 'src/constants';
import store from 'src/redux/store';
import { Application } from 'src/types/application';
import { saveAs } from 'file-saver';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';

export const getStepStatusColor = (step) => {
  const reduxState = store.getState();
  if (reduxState?.applicationsReducer?.currentApplication) {
    let color = '';
    const application: Application =
      reduxState?.applicationsReducer?.currentApplication;
    if (step.label === formSteps.PERSONAL_INFORMATION) {
      if (application?.personalInformation?.stage === 'complete') {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.PREFERENCES_AND_AVAILABILITY) {
      if (
        application?.employeePreferences?.stage === 'complete' &&
        application?.availability?.stage === 'complete' &&
        application?.workingTimeRegulation?.stage === 'complete'
        // application?.referAFriend?.stage === 'complete'
      ) {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.RIGHT_TO_WORK) {
      if (application?.rightToWork?.stage === 'complete') {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.PAYMENT_INFO) {
      if (application?.paymentInfo?.stage === 'complete') {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.WORK_HISTORY_AND_REFERENCES) {
      if (
        application?.employmentHistory?.stage === 'complete' &&
        application?.professionalReferences?.stage === 'complete'
      ) {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.FITNESS_TO_WORK) {
      if (
        application?.fitnessToWork?.stage === 'complete' &&
        application?.healthChecklist?.stage === 'complete'
      ) {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.CRIMINAL_RECORD_CHECK) {
      if (
        application?.dbs?.stage === 'complete' &&
        application?.addressHistory?.stage === 'complete' &&
        application?.overseasPoliceCheck?.stage === 'complete' &&
        application?.declarations?.stage === 'complete'
      ) {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.PROFESSIONAL_INFORMATION) {
      if (
        application?.equalOpportunityMonitoring?.stage === 'complete' &&
        application?.nursingCompetencyChecklist?.stage === 'complete'
      ) {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.DOCUMENTS) {
      if (application?.documents?.stage === 'complete') {
        color = '#57CA22';
      }
    } else if (step.label === formSteps.EMPLOYEE_DECLARATION) {
      if (application?.declarationsAndConsent?.stage === 'complete') {
        color = '#57CA22';
      }
    }

    return color;
  }
};

export const getDateFormatted = (date) => {
  if (date) {
    return format(new Date(date), 'dd/MM/yyyy');
  }

  return '';
};

export const downloadFile = async (filePath) => {
  try {
    const res: any = await backend.post(
      '/applications/download-file',
      { filePath },
      { responseType: 'blob' }
    );

    const blob = new Blob([res.data]);
    saveAs(blob, filePath);
  } catch (error) {
    renderHttpErrors(error);
  }
};
