import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import EmploymentHistoryModal from './modals/EmploymentHistory';
import ProfessionalReferencesModal from './modals/ProfessionalReferences';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import FormStatus from '../FormStatus';

const WorkHistoryAndReferences = ({ application }) => {
  const [modalOpen, setModalOpen] = React.useState({
    employmentHistoryModal: false,
    professionalReferencesModal: false
  });

  const handleModalState = (
    modal: 'employmentsModal' | 'professionalReferencesModal',
    changeType: 'open' | 'close'
  ) => {
    switch (modal) {
      case 'employmentsModal':
        setModalOpen((modalState) => ({
          ...modalState,
          employmentHistoryModal: changeType === 'open' ? true : false
        }));
        break;

      case 'professionalReferencesModal':
        setModalOpen((modalState) => ({
          ...modalState,
          professionalReferencesModal: changeType === 'open' ? true : false
        }));
        break;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Employment History
              </Typography>
              <FormStatus stage={application?.employmentHistory?.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Information about your employment history
            </Typography>

            <Button
              onClick={() => handleModalState('employmentsModal', 'open')}
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            px: 1
          }}
        >
          <CardContent>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="h4" noWrap>
                Professional References
              </Typography>
              <FormStatus stage={application?.professionalReferences?.stage} />
            </Box>

            <Typography variant="subtitle1" noWrap>
              Provide a list of your professional references
            </Typography>

            <Button
              onClick={() =>
                handleModalState('professionalReferencesModal', 'open')
              }
              sx={{ marginTop: 2 }}
              variant="contained"
              fullWidth
            >
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      {/* Modals */}
      <EmploymentHistoryModal
        open={modalOpen.employmentHistoryModal}
        handleModalState={handleModalState}
        formData={application.employmentHistory}
      />
      <ProfessionalReferencesModal
        open={modalOpen.professionalReferencesModal}
        handleModalState={handleModalState}
        formData={application.professionalReferences}
      />
    </Grid>
  );
};

export default connect((state: RootState) => ({
  application: state.applicationsReducer.currentApplication
}))(WorkHistoryAndReferences);
