import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Typography,
  Divider,
  MenuItem,
  Box,
  Autocomplete,
  Tooltip,
  IconButton,
  Checkbox,
  List,
  ListItem
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import countries from 'src/__mock__/countries.json';
import { InfoOutlined } from '@mui/icons-material';
import FileInput from 'src/components/FileUploader';
import React from 'react';
import DateSelector from 'src/components/DateSelector';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { getSingleApplication } from 'src/redux/actions/applications';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';

type Props = {
  open: boolean;
  handleModalState: any;
  formData?: any;
  getSingleApplication;
};

const submitDbs = async (values) => {
  try {
    await backend.post('/forms/applications/dbs', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const previousAddressFields = {
  dateMovedIn: new Date(),
  dateMovedOut: new Date(),
  addressLine1: '',
  addressLine2: '',
  cityTown: '',
  country: '',
  postCode: ''
};

const DBSModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('dbsModal', 'close')}
    >
      <Formik
        initialValues={{
          existingDbs: {
            holdACurrentDbsCheck:
              formData?.existingDbs?.holdACurrentDbsCheck || '',
            registeredYourDbsWithUpdateService:
              formData?.existingDbs?.registeredYourDbsWithUpdateService || '',
            doYouGivePermissionToCheckDbsCertificate:
              formData?.existingDbs?.doYouGivePermissionToCheckDbsCertificate ||
              '',
            disclosureNumber: formData?.existingDbs?.disclosureNumber || '',
            disclosureExpiration:
              new Date(formData?.existingDbs?.disclosureExpiration) || '',
            holdCurrentDisclosureScotland:
              formData?.existingDbs?.holdCurrentDisclosureScotland || '',
            disclosureNumberScotland:
              formData?.existingDbs?.disclosureNumberScotland || '',
            disclosureExpirationScotland:
              new Date(formData?.existingDbs?.disclosureExpirationScotland) ||
              ''
          },
          // If existing dbs is no
          title: formData?.title || '',
          currentForeName: formData?.currentForeName || '',
          currentMiddleName: formData?.currentMiddleName || '',
          currentSurName: formData?.currentSurName || '',
          gender: formData?.gender || '',
          everUsedAnyForeNamesBefore:
            formData?.everUsedAnyForeNamesBefore || '',
          otherForenames: formData?.otherForenames || '',
          everUsedAnySurNamesBefore: formData?.everUsedAnySurNamesBefore || '',
          otherSurNames: formData?.otherSurNames || '',
          changedSurNameSinceBirth: formData?.changedSurNameSinceBirth || '',
          mothersMaidenName: formData?.mothersMaidenName || '',
          nationalityAtBirth: formData?.nationalityAtBirth || '',
          changedNationalitySinceBirth:
            formData?.changedNationalitySinceBirth || '',
          doYouHaveAnyConvictions: formData?.doYouHaveAnyConvictions || '',
          consentToApplyForDbs: formData?.consentToApplyForDbs || '',

          // ADDRESS HIST
          currentAddress: {
            dateMovedIn:
              new Date(formData?.currentAddress?.dateMovedIn) || new Date(),
            addressLine1: formData?.currentAddress?.addressLine1,
            addressLine2: formData?.currentAddress?.addressLine2,
            cityTown: formData?.currentAddress?.cityTown,
            country: formData?.currentAddress?.country,
            postCode: formData?.currentAddress?.postCode
          },
          changedAddress: formData?.changedAddress || 'No',
          previousAddress: formData?.previousAddress?.length
            ? formData?.previousAddress?.map((address) => ({
                dateMovedIn: new Date(address?.dateMovedIn) || new Date(),
                dateMovedOut: new Date(address?.dateMovedOut) || new Date(),
                addressLine1: address?.addressLine1 || '',
                addressLine2: address?.addressLine2 || '',
                cityTown: address?.cityTown || '',
                country: address?.country || '',
                postCode: address?.postCode || ''
              }))
            : [previousAddressFields],
          everLivedOutsideUK: formData?.everLivedOutsideUK || 'No',
          anyConvictions: formData?.anyConvictions || '',
          consent: formData?.consent || 'No'
        }}
        onSubmit={async (values) => {
          if (await submitDbs({ ...values, applicationId })) {
            handleModalState('dbsModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>DBS</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="subtitle1">
                Pulse Care would require obtaining a current DBS if you are
                seeking employment with our agency. This will be via Disclosure
                and Barring Service (DBS) the rehabilitation of oﬀenders
                exceptions order 1975 (2013) (England and Wales). If you are
                seeking employment in Scotland, you may be required to complete
                additional forms which comply with the Protecting Vulnerable
                Groups (PVG) scheme. By providing the below details you are
                giving consent to complete your Disclosure and Barring Service
                (DBS) checks and consent to retain your DBS on file.
              </Typography>
              <Grid container spacing={2} my={2}>
                <Grid item xs={12}>
                  <Box sx={{ background: '#f8f8f8' }} p={2} borderRadius={1}>
                    <Typography mb={2}>
                      Do you hold a current DBS check required for the role
                      applied for?
                    </Typography>
                    <TextField
                      fullWidth
                      name="existingDbs.holdACurrentDbsCheck"
                      value={values.existingDbs.holdACurrentDbsCheck}
                      onChange={handleChange}
                      size="small"
                      select
                    >
                      <MenuItem value="No">No</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
              </Grid>

              {/* If DBS Existing */}
              {values.existingDbs.holdACurrentDbsCheck === 'Yes' && (
                <Grid container spacing={1} mt={2}>
                  <Grid item xs={12}>
                    <Box sx={{ background: '#f8f8f8' }} p={2} borderRadius={1}>
                      <Typography mb={2}>
                        Have You Registered Your DBS With Update Service?
                      </Typography>
                      <TextField
                        fullWidth
                        name="existingDbs.registeredYourDbsWithUpdateService"
                        value={
                          values.existingDbs.registeredYourDbsWithUpdateService
                        }
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>

                  {values.existingDbs.registeredYourDbsWithUpdateService ===
                    'Yes' && (
                    <Grid item xs={12}>
                      <Box
                        sx={{ background: '#f8f8f8' }}
                        p={2}
                        borderRadius={1}
                      >
                        <Typography mb={2}>
                          Do you give permission to check your DBS certificate
                          via the update Service
                        </Typography>
                        <TextField
                          fullWidth
                          name="existingDbs.doYouGivePermissionToCheckDbsCertificate"
                          value={
                            values.existingDbs
                              .doYouGivePermissionToCheckDbsCertificate
                          }
                          onChange={handleChange}
                          size="small"
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box sx={{ background: '#f8f8f8' }} p={2} borderRadius={1}>
                      <Typography mb={2}>
                        Please enter 12 digit number mentioned on your dbs
                      </Typography>
                      <TextField
                        fullWidth
                        name="existingDbs.disclosureNumber"
                        value={values.existingDbs.disclosureNumber}
                        onChange={handleChange}
                        size="small"
                        label="Disclosure Number"
                        placeholder="Disclosure Number"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ background: '#f8f8f8' }} p={2} borderRadius={1}>
                      <Typography mb={2}>Disclosure Expiration</Typography>
                      <DateSelector
                        handleChange={(e) => {
                          setFieldValue('existingDbs.disclosureExpiration', e);
                        }}
                        value={values.existingDbs.disclosureExpiration}
                        label="Disclosure Expiration"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ background: '#f8f8f8' }} p={2} borderRadius={1}>
                      <Typography mb={2}>
                        Do you hold a current Disclosure Scotland (PVG) check
                        required for the role applied for?
                      </Typography>
                      <TextField
                        fullWidth
                        name="existingDbs.holdCurrentDisclosureScotland"
                        value={values.existingDbs.holdCurrentDisclosureScotland}
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  {values.existingDbs.holdCurrentDisclosureScotland ===
                    'Yes' && (
                    <>
                      <Grid item xs={12}>
                        <Box
                          sx={{ background: '#f8f8f8' }}
                          p={2}
                          borderRadius={1}
                        >
                          <Typography mb={2}>PVG Disclosure Number</Typography>
                          <TextField
                            fullWidth
                            name="existingDbs.disclosureNumberScotland"
                            value={values.existingDbs.disclosureNumberScotland}
                            onChange={handleChange}
                            size="small"
                            label="PVG Disclosure Number"
                            placeholder="PVG Disclosure Number"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{ background: '#f8f8f8' }}
                          p={2}
                          borderRadius={1}
                        >
                          <Typography mb={2}>
                            PVG Disclosure Expiration
                          </Typography>
                          <DateSelector
                            handleChange={(e) => {
                              setFieldValue(
                                'existingDbs.disclosureExpirationScotland',
                                e
                              );
                            }}
                            value={
                              values.existingDbs.disclosureExpirationScotland
                            }
                            label="PVG Disclosure Expiration"
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}

              {/* If DBS Non-Existing */}
              {values.existingDbs.holdACurrentDbsCheck === 'No' && (
                <>
                  <Box mb={4}>
                    <Grid
                      container
                      bgcolor={'#f8f8f8'}
                      p={2}
                      borderRadius={1}
                      gap={2}
                    >
                      <Typography variant="body1" fontWeight={'bold'} mb={1}>
                        Personal Information
                      </Typography>

                      <Grid item xs={12}>
                        <TextField
                          label="Title"
                          placeholder="Title"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          fullWidth
                          size="small"
                          select
                        >
                          <MenuItem value="Mr">Mr</MenuItem>
                          <MenuItem value="Mrs">Mrs</MenuItem>
                          <MenuItem value="Miss">Miss</MenuItem>
                          <MenuItem value="Ms">Ms</MenuItem>
                          <MenuItem value="Dr">Dr</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="currentForeName"
                          value={values.currentForeName}
                          onChange={handleChange}
                          size="small"
                          label="Current Forename(s)"
                          placeholder="Current Forename(s)"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="currentMiddleName"
                          value={values.currentMiddleName}
                          onChange={handleChange}
                          size="small"
                          label="Current Middlename(s)"
                          placeholder="Current Middlename(s)"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="currentSurName"
                          value={values.currentSurName}
                          onChange={handleChange}
                          size="small"
                          label="Current Surname(s)"
                          placeholder="Current Surname(s)"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={{ fontSize: '0.6rem' }}
                          size="small"
                          select
                          label="Gender"
                          onChange={handleChange}
                          fullWidth
                          name="gender"
                          value={values.gender}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="everUsedAnyForeNamesBefore"
                          value={values.everUsedAnyForeNamesBefore}
                          onChange={handleChange}
                          size="small"
                          label="Have you ever used any other forenames?"
                          placeholder="Have you ever used any other forenames?"
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      {values.everUsedAnyForeNamesBefore === 'Yes' && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="otherForenames"
                            value={values.otherForenames}
                            onChange={handleChange}
                            size="small"
                            label="Please specify previous forenames"
                            placeholder="Please specify previous forenames"
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="everUsedAnySurNamesBefore"
                          value={values.everUsedAnySurNamesBefore}
                          onChange={handleChange}
                          size="small"
                          label="Have you ever used any other Surnames?"
                          placeholder="Have you ever used any other Surnames?"
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      {values.everUsedAnySurNamesBefore === 'Yes' && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="otherSurNames"
                            value={values.otherSurNames}
                            onChange={handleChange}
                            size="small"
                            label="Please specify other Surnames"
                            placeholder="Please specify other Surnames"
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="changedSurNameSinceBirth"
                          value={values.changedSurNameSinceBirth}
                          onChange={handleChange}
                          size="small"
                          label="Have you changed your Surnames since birth?"
                          placeholder="Have you changed your Surnames since birth?"
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="mothersMaidenName"
                          value={values.mothersMaidenName}
                          onChange={handleChange}
                          size="small"
                          label="What is your Mother's maiden name?"
                          placeholder="What is your Mother's maiden name?"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="nationalityAtBirth"
                          value={values.nationalityAtBirth}
                          onChange={handleChange}
                          size="small"
                          label="Nationlity at Birth"
                          placeholder="Nationlity at Birth"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="changedNationalitySinceBirth"
                          value={values.changedNationalitySinceBirth}
                          onChange={handleChange}
                          size="small"
                          label="Have you changed your nationality since birth?"
                          placeholder="Have you changed your nationality since birth?"
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="doYouHaveAnyConvictions"
                          value={values.doYouHaveAnyConvictions}
                          onChange={handleChange}
                          size="small"
                          label="Do you have any convictions, cautions, reprimands or final warnings which would not be filtered in line with current guidance"
                          placeholder="Do you have any convictions, cautions, reprimands or final warnings which would not be filtered in line with current guidance"
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="consentToApplyForDbs"
                          value={values.consentToApplyForDbs}
                          onChange={handleChange}
                          size="small"
                          label="I consent to Pulse Care using the information provided to applyfor a DBS on my behalf"
                          placeholder="I consent to Pulse Care using the information provided to applyfor a DBS on my behalf"
                          select
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    {/* Current Address */}
                    <Grid item xs={12} my={2}>
                      <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                        <Typography variant="body1" fontWeight={'bold'} mb={1}>
                          Current Address
                        </Typography>
                        <Box
                          className="innerInputContainer"
                          display={'flex'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <DateSelector
                            label="Date moved in"
                            handleChange={(date) => {
                              setFieldValue('currentAddress.dateMovedIn', date);
                            }}
                            value={values.currentAddress.dateMovedIn}
                          />
                          <TextField
                            name="currentAddress.addressLine1"
                            value={values.currentAddress.addressLine1}
                            fullWidth
                            onChange={handleChange}
                            label="Address Line 1"
                            placeholder="Address Line 1"
                            size="small"
                          />
                          <TextField
                            name="currentAddress.addressLine2"
                            value={values.currentAddress.addressLine2}
                            fullWidth
                            onChange={handleChange}
                            label="Address Line 2"
                            placeholder="Address Line 2"
                            size="small"
                          />
                          <TextField
                            name="currentAddress.cityTown"
                            value={values.currentAddress.cityTown}
                            fullWidth
                            onChange={handleChange}
                            label="City / Town"
                            placeholder="City / Town"
                            size="small"
                          />
                          <TextField
                            name="currentAddress.country"
                            value={values.currentAddress.country}
                            fullWidth
                            onChange={handleChange}
                            label="Country"
                            placeholder="Country"
                            size="small"
                          />
                          <TextField
                            name="currentAddress.postCode"
                            value={values.currentAddress.postCode}
                            fullWidth
                            onChange={handleChange}
                            label="Post Code"
                            placeholder="Post Code"
                            size="small"
                          />
                        </Box>
                      </Box>
                    </Grid>

                    {/* Changed Address */}
                    <Grid item xs={12} my={2}>
                      <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                        <Box
                          className="innerInputContainer"
                          display={'flex'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <TextField
                            name="changedAddress"
                            value={values.changedAddress}
                            fullWidth
                            onChange={handleChange}
                            label="Have you ever changed your address?"
                            placeholder="Have you ever changed your address?"
                            size="small"
                            select
                          >
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                          </TextField>
                        </Box>
                      </Box>
                    </Grid>

                    {/* Previous Address */}
                    {values.changedAddress === 'Yes' && (
                      <Grid item xs={12} my={2}>
                        <FieldArray name="previousAddress">
                          {({ remove, push }) => {
                            return values.previousAddress.map(
                              (address, idx) => {
                                return (
                                  <React.Fragment>
                                    <Box
                                      mt={
                                        values.previousAddress.length > 0
                                          ? 2
                                          : 0
                                      }
                                      bgcolor={'#f8f8f8'}
                                      p={2}
                                      borderRadius={1}
                                    >
                                      <Typography
                                        variant="body1"
                                        fontWeight={'bold'}
                                        mb={1}
                                      >
                                        Previous Address ({idx + 1})
                                      </Typography>
                                      <Box
                                        className="innerInputContainer"
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={2}
                                      >
                                        <DateSelector
                                          label="Date moved in"
                                          handleChange={(date) => {
                                            setFieldValue(
                                              `previousAddress[${idx}].dateMovedIn`,
                                              date
                                            );
                                          }}
                                          value={
                                            values.previousAddress[idx]
                                              .dateMovedIn
                                          }
                                        />
                                        <DateSelector
                                          label="Date moved out"
                                          handleChange={(date) => {
                                            setFieldValue(
                                              `previousAddress[${idx}].dateMovedOut`,
                                              date
                                            );
                                          }}
                                          value={
                                            values.previousAddress[idx]
                                              .dateMovedOut
                                          }
                                        />
                                        <TextField
                                          name={`previousAddress[${idx}].addressLine1`}
                                          value={
                                            values.previousAddress[idx]
                                              .addressLine1
                                          }
                                          fullWidth
                                          onChange={handleChange}
                                          label="Address Line 1"
                                          placeholder="Address Line 1"
                                          size="small"
                                        />
                                        <TextField
                                          name={`previousAddress[${idx}].addressLine2`}
                                          value={
                                            values.previousAddress[idx]
                                              .addressLine2
                                          }
                                          fullWidth
                                          onChange={handleChange}
                                          label="Address Line 2"
                                          placeholder="Address Line 2"
                                          size="small"
                                        />
                                        <TextField
                                          name={`previousAddress[${idx}].cityTown`}
                                          value={
                                            values.previousAddress[idx].cityTown
                                          }
                                          fullWidth
                                          onChange={handleChange}
                                          label="City / Town"
                                          placeholder="City / Town"
                                          size="small"
                                        />
                                        <TextField
                                          name={`previousAddress[${idx}].country`}
                                          value={
                                            values.previousAddress[idx].country
                                          }
                                          fullWidth
                                          onChange={handleChange}
                                          label="Country"
                                          placeholder="Country"
                                          size="small"
                                        />
                                        <TextField
                                          name={`previousAddress[${idx}].postCode`}
                                          value={
                                            values.previousAddress[idx].postCode
                                          }
                                          fullWidth
                                          onChange={handleChange}
                                          label="Post Code"
                                          placeholder="Post Code"
                                          size="small"
                                        />
                                      </Box>
                                      <Box
                                        mt={2}
                                        display={'flex'}
                                        justifyContent={
                                          idx + 1 ===
                                          values.previousAddress.length
                                            ? 'space-between'
                                            : 'end'
                                        }
                                      >
                                        <Button
                                          size="small"
                                          sx={{
                                            display:
                                              idx ===
                                              values.previousAddress.length - 1
                                                ? 'block'
                                                : 'none'
                                          }}
                                          variant="text"
                                          onClick={() =>
                                            push({
                                              ...previousAddressFields,
                                              id:
                                                values.previousAddress.length +
                                                100
                                            })
                                          }
                                        >
                                          Add more
                                        </Button>
                                        <Button
                                          sx={{ display: idx === 0 && 'none' }}
                                          color="error"
                                          variant="text"
                                          size="small"
                                          onClick={(e) => remove(idx)}
                                        >
                                          Delete
                                        </Button>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                );
                              }
                            );
                          }}
                        </FieldArray>
                      </Grid>
                    )}

                    {/*  Lived / Chargesheets */}
                    <Grid item xs={12} my={2}>
                      <Box
                        bgcolor={'#f8f8f8'}
                        display={'flex'}
                        flexDirection={'column'}
                        p={2}
                        gap={2}
                        borderRadius={1}
                      >
                        <Box
                          className="innerInputContainer"
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                        >
                          <Typography>
                            Have you lived outside the UK for more than 6
                            MONTHSin the past 5 YEARS?
                          </Typography>
                          <TextField
                            name="everLivedOutsideUK"
                            value={values.everLivedOutsideUK}
                            fullWidth
                            onChange={handleChange}
                            size="small"
                            select
                          >
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                          </TextField>
                        </Box>
                        <Box
                          className="innerInputContainer"
                          display={'flex'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <Typography>
                            Do you have any convictions, cautions, reprimands or
                            final warnings which would not be filtered in line
                            with current guidance?
                          </Typography>
                          <TextField
                            name="anyConvictions"
                            value={values.anyConvictions}
                            fullWidth
                            onChange={handleChange}
                            size="small"
                            select
                          >
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                          </TextField>
                        </Box>
                      </Box>
                    </Grid>

                    {/* Consent */}
                    <Grid item xs={12} my={2}>
                      <Box bgcolor={'#f8f8f8'} p={0} borderRadius={1}>
                        <Box
                          className="innerInputContainer"
                          display={'flex'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <List>
                            <ListItem>
                              <Typography
                                fontSize={'small'}
                                variant="subtitle2"
                              >
                                I hereby confirm that the information I have
                                provided in this application is complete and
                                true. I further understand that to knowingly
                                provide false information for this application
                                is a criminal offence.
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography
                                fontSize={'small'}
                                variant="subtitle2"
                              >
                                I have read, understood and accept the DDC
                                Privacy Policy. I give my consent for this
                                information to be passed to the Disclosure and
                                Barring Service for the purpose of applying for
                                a Disclosure.
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography
                                fontSize={'small'}
                                variant="subtitle2"
                              >
                                I have read the Standard/Enhanced Check Privacy
                                Policy for applicants and I understand how DBS
                                will process my personal data and the options
                                available to me for submitting an application.
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography
                                fontSize={'small'}
                                variant="subtitle2"
                              >
                                I consent to the DBS providing an electronic
                                result directly to the registered body that has
                                submitted my application. I understand that an
                                electronic result contains a message that
                                indicates either the certificate is blank or to
                                await certificate which will indicate that my
                                certificate contains information. In some cases
                                the registered body may provide this information
                                directly to my employer prior to me receiving my
                                certificate.
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography
                                fontSize={'small'}
                                variant="subtitle2"
                              >
                                I understand if I do not consent to an
                                electronic result being issued to the registered
                                body submitting my application that I must not
                                proceed with this application and I should
                                submit a paper application form.
                              </Typography>
                            </ListItem>
                            {/* <ListItem>
                          <Typography fontSize={'small'} variant="subtitle2">
                            I understand that to withdraw my consent whilst my
                            application is in progress I must contact the DBS
                            helpline 03000 200 190. My application will then be
                            withdrawn.
                          </Typography>
                        </ListItem> */}
                          </List>
                        </Box>
                      </Box>
                    </Grid>

                    {/* Changed Address */}
                    <Grid item xs={12} my={2}>
                      <Box bgcolor={'#f8f8f8'} p={2} borderRadius={1}>
                        <Box
                          className="innerInputContainer"
                          display={'flex'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <Typography>
                            I have read and understood the above information,
                            and consent to the submission of the application
                          </Typography>
                          <TextField
                            name="consent"
                            value={values.consent}
                            fullWidth
                            onChange={handleChange}
                            size="small"
                            select
                          >
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                          </TextField>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </>
              )}
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleModalState('dbsModal', 'close')}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(DBSModal);
