import {
  APPLICATIONS_FETCH_SUCCESS,
  APPLICATIONS_FETCH_FAILED,
  APPLICATION_SINGLE_FETCH_SUCCESS,
  APPLICATION_SINGLE_LOADING
} from '../actions/types';

const initialState = {
  applications: [],
  loading: true,
  currentApplication: {},
  currentApplicationLoading: true
};

const applicationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case APPLICATION_SINGLE_LOADING:
      return {
        ...state,
        currentApplicationLoading: true
      };

    case APPLICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: payload,
        currentApplicationLoading: true
      };

    case APPLICATION_SINGLE_FETCH_SUCCESS:
      return {
        ...state,
        currentApplication: payload,
        currentApplicationLoading: false
      };

    default:
      return state;
  }
};

export default applicationsReducer;
