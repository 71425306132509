import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Typography,
  Divider,
  MenuItem,
  Box,
  Checkbox,
  Link
} from '@mui/material';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { getSingleApplication } from 'src/redux/actions/applications';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import PaymentAndPolicy from 'src/PaymentPolicy.pdf';
import ESGPolicy from 'src/ESG Policy.pdf';
import ModernSlaveryPolicy from 'src/Modern Slavery Policy.pdf';
import WorkerHandbook from 'src/Pulse Care Agency-Worker-Handbook-Version 2.0.pdf';

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const submitDeclarationsAndConsent = async (values) => {
  try {
    await backend.post('/forms/applications/declarations-and-consent', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const DeclarationsAndConsentModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('declarationsAndConsentModal', 'close')}
    >
      <Formik
        initialValues={{
          agreeToDocumentationChange:
            formData?.agreeToDocumentationChange || 'No',
          agreeToComplyWithRequirements:
            formData?.agreeToComplyWithRequirements || 'No',
          agreeToStaffHandbook: formData?.agreeToStaffHandbook || 'No',
          agreeToMonthlyPinChecks: formData?.agreeToMonthlyPinChecks || 'No',
          agreeToAbideByTheCodeOfConduct:
            formData?.agreeToAbideByTheCodeOfConduct || 'No',
          agreeToAuditDBSByThirdParty:
            formData?.agreeToAuditDBSByThirdParty || 'No',
          informationProvidedisCorrect:
            formData?.informationProvidedisCorrect || 'No',
          agreeToUseMyPersonalInformation:
            formData?.agreeToUseMyPersonalInformation || 'No',
          agreeToSendMyInfoToUmbrellaCompanies:
            formData?.agreeToSendMyInfoToUmbrellaCompanies || 'No',
          consentToSaveHardCopyOfDBS:
            formData?.consentToSaveHardCopyOfDBS || 'No',
          consentToSubmitMyAnnualDBS:
            formData?.consentToSubmitMyAnnualDBS || 'No',
          consentToBearTheCostOfDbsRenewal:
            formData?.consentToBearTheCostOfDbsRenewal || 'No',
          consentToCheckWithHomeOfficeForEligibility:
            formData?.consentToCheckWithHomeOfficeForEligibility || 'No',
          consentToCheckMyTrainingCertifications:
            formData?.consentToCheckMyTrainingCertifications || 'No',
          consentToSendMyInfoToHospitals:
            formData?.consentToSendMyInfoToHospitals || 'No',
          consentToSendMyInfoToAttendanceApp:
            formData?.consentToSendMyInfoToAttendanceApp || 'No',
          consentToRecordMyVideoInterview:
            formData?.consentToRecordMyVideoInterview || 'No',
          consentToUsageOfThirdPartyAttendanceApp:
            formData?.consentToUsageOfThirdPartyAttendanceApp || 'No',
          consentToPay50PoundsForDBS:
            formData?.consentToPay50PoundsForDBS || 'No',
          consentToPay6PoundsForIDChecks:
            formData?.consentToPay6PoundsForIDChecks || 'No',
          consentToPay15PoundsForHealthScreening:
            formData?.consentToPay15PoundsForHealthScreening || 'No',
          consentToPayForTheTraining:
            formData?.consentToPayForTheTraining || 'No',
          readTheAgencyWorkerHandbook:
            formData?.readTheAgencyWorkerHandbook || 'No',
          acceptInductionPolicy: formData?.acceptInductionPolicy || false,
          acceptESGPolicy: formData?.acceptESGPolicy || false,
          acceptModernSlaveryPolicy:
            formData?.acceptModernSlaveryPolicy || false
        }}
        onSubmit={async (values) => {
          if (
            await submitDeclarationsAndConsent({ ...values, applicationId })
          ) {
            handleModalState('declarationsAndConsentModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Declaration</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="subtitle1">
                Please refer to our Pulse Care website for our T&Cs as this
                includes all guidelines and standards required under the NHS
                employers and standards and all framework agreements.
              </Typography>
              <Typography variant="subtitle1">
                Please be sure to check all information provided on this
                registration form, making sure all areas have been answered
                accurately and honestly without missing any key facts or
                information. any inconsistencies of non-disclosures of
                information related to previous and current employment
                including: ﬁtness to practice, safeguarding related matters,
                investigations, convictions, criminal activity or professional
                suspensions, will with immediate eﬀect void your application for
                employment with Pulse Care.
              </Typography>
              <Typography variant="subtitle1">
                You are on zero hour contract which does not include holiday pay
                and sick leave allowance or other beneﬁts. Please note that any
                claims relating to Covid-19 coronavirus will not be covered
                under any policies.
              </Typography>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={4}
                    bgcolor={'#f8f8f8'}
                    p={2}
                    borderRadius={1}
                  >
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                    >
                      <Typography>
                        I understand that during the course of my engagement
                        with Pulse Care Limited, my compliance and documentation
                        may require updating form time to time.
                      </Typography>
                      <TextField
                        name="agreeToDocumentationChange"
                        value={values.agreeToDocumentationChange}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I agree to comply with these requirements as necessary
                        and within the timelines given to prevent delays on work
                        being offered. If any of your compliance items lapse, it
                        may cause the suspension/and or termination of your
                        placement.
                      </Typography>
                      <TextField
                        name="agreeToComplyWithRequirements"
                        value={values.agreeToComplyWithRequirements}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I have received, read and understood a copy of the Staff
                        Handbook. I have received the agency's AWR policy. I
                        have read and understood the terms.
                      </Typography>
                      <TextField
                        name="agreeToStaffHandbook"
                        value={values.agreeToStaffHandbook}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent Pulse Care Limited completing my monthly
                        PIN/GMC checks, my training record checks and my
                        qualification checks
                      </Typography>
                      <TextField
                        name="agreeToMonthlyPinChecks"
                        value={values.agreeToMonthlyPinChecks}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I understand all the policies and procedures and will
                        abide by the code of conduct at all times.{' '}
                        <a href={PaymentAndPolicy} target="blank">
                          (Download Policies & Procedures)
                        </a>
                      </Typography>
                      <TextField
                        name="agreeToAbideByTheCodeOfConduct"
                        value={values.agreeToAbideByTheCodeOfConduct}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        In line with the nature of the work I will be
                        undertaking, I understand that the documentation
                        including Disclosure And Barring Service (DBS) I have
                        given in line with compliance procedures, may be audited
                        even by third party auditors in relation to the
                        provision of the services at any time and I consent to
                        this.
                      </Typography>
                      <TextField
                        name="agreeToAuditDBSByThirdParty"
                        value={values.agreeToAuditDBSByThirdParty}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I Declare That All Information Provided Within This
                        Registration Form For The Application Of Employment With
                        Pulse Care, Are True To The Best Of My Knowledge. I
                        Agree To Comply With All The Requirements And
                        Declarations Provided To Me By Pulse Care Listed In The
                        T&C's And Throughout The Registration. If There Are Any
                        Changes To The Information I Provide, I Will Notify
                        Pulse Care Immediately.
                      </Typography>
                      <TextField
                        name="informationProvidedisCorrect"
                        value={values.informationProvidedisCorrect}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent to Pulse care using my personel information
                        for work, compliances, training and payroll emails and
                        calls
                      </Typography>
                      <TextField
                        name="agreeToUseMyPersonalInformation"
                        value={values.agreeToUseMyPersonalInformation}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent to send my personel information to our
                        approved Umbrella companies when not paid via PAYE.
                      </Typography>
                      <TextField
                        name="agreeToSendMyInfoToUmbrellaCompanies"
                        value={values.agreeToSendMyInfoToUmbrellaCompanies}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent Pulse Care to save a hard copy of my DBS.
                      </Typography>
                      <TextField
                        name="consentToSaveHardCopyOfDBS"
                        value={values.consentToSaveHardCopyOfDBS}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent Pulse Care to submit my annual DBS
                        application.
                      </Typography>
                      <TextField
                        name="consentToSubmitMyAnnualDBS"
                        value={values.consentToSubmitMyAnnualDBS}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent to bear the cost associated with the renewal
                        of the (DBS) check, in accordance with the rates charged
                        by the DBS service provider.
                      </Typography>
                      <TextField
                        name="consentToBearTheCostOfDbsRenewal"
                        value={values.consentToBearTheCostOfDbsRenewal}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent Pulse Care to check with the Home Office to
                        confirm my employment eligibility when needed.
                      </Typography>
                      <TextField
                        name="consentToCheckWithHomeOfficeForEligibility"
                        value={
                          values.consentToCheckWithHomeOfficeForEligibility
                        }
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>

                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent Pulse Care to check my training certifications
                        with external certificate issuers.
                      </Typography>
                      <TextField
                        name="consentToCheckMyTrainingCertifications"
                        value={values.consentToCheckMyTrainingCertifications}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent Pulse Care to send my personal information to
                        hospitals, care homes, trusts, etc.
                      </Typography>
                      <TextField
                        name="consentToSendMyInfoToHospitals"
                        value={values.consentToSendMyInfoToHospitals}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent Pulse Care to send my personal information to
                        attendance app.
                      </Typography>
                      <TextField
                        name="consentToSendMyInfoToAttendanceApp"
                        value={values.consentToSendMyInfoToAttendanceApp}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent Pulse Care to record my online video interview
                        for audit purposes.
                      </Typography>
                      <TextField
                        name="consentToRecordMyVideoInterview"
                        value={values.consentToRecordMyVideoInterview}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent that approved third-party attendance
                        application may be used to track my regular attendance.
                      </Typography>
                      <TextField
                        name="consentToUsageOfThirdPartyAttendanceApp"
                        value={values.consentToUsageOfThirdPartyAttendanceApp}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent to take the financial responsibility for the
                        payment of £52 associated with my (DBS) check. This
                        payment may be made either in advance or through the
                        reimbursement of my salary, as deemed appropriate by the
                        company. This is exempted, if the DBS(Enhanced) is
                        already on update service
                      </Typography>
                      <TextField
                        name="consentToPay50PoundsForDBS"
                        value={values.consentToPay50PoundsForDBS}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent to take the financial responsibility for the
                        payment of £6 associated with my ID checks. This payment
                        may be made either in advance or through the
                        reimbursement of my salary, as deemed appropriate by the
                        company.
                      </Typography>
                      <TextField
                        name="consentToPay6PoundsForIDChecks"
                        value={values.consentToPay6PoundsForIDChecks}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent to take the financial responsibility for the
                        payment of £15 associated with my Health Screening. This
                        payment may be made either in advance or through the
                        reimbursement of my salary, as deemed appropriate by the
                        company.
                      </Typography>
                      <TextField
                        name="consentToPay15PoundsForHealthScreening"
                        value={values.consentToPay15PoundsForHealthScreening}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I consent to pay for training the amount applicable as
                        per training required.
                      </Typography>
                      <TextField
                        name="consentToPayForTheTraining"
                        value={values.consentToPayForTheTraining}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography>
                        I have read the Agency Worker Handbook, which describes
                        my duties as an Agency Worker as well as the aims,
                        rules, rewards, and expectations of Pulsecare and its
                        clients. I am familiar with the information in this
                        handbook. By signing below, I certify that I have read,
                        understand, accept, and agree to abide by the
                        instructions in the Pulse Care Ltd Agency Worker
                        Handbook. I also affirm that I am aware that I must
                        notify Pulse Care Ltd right away if my eligibility to
                        practise medicine or to maintain my professional
                        registration changes.{' '}
                        <a href={WorkerHandbook} target="blank">
                          (Download Worker Handbook)
                        </a>
                      </Typography>
                      <TextField
                        name="readTheAgencyWorkerHandbook"
                        value={values.readTheAgencyWorkerHandbook}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="row"
                      justifyContent={'space-between'}
                      gap={2}
                      alignItems={'center'}
                    >
                      <Typography>
                        By ticking this box, I acknowledge, understand, accept
                        and agree to the Pulse Care Induction Policy
                      </Typography>
                      <Checkbox
                        onChange={(e) =>
                          setFieldValue(
                            'acceptInductionPolicy',
                            e.target.checked
                          )
                        }
                        checked={values.acceptInductionPolicy}
                      />
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="row"
                      justifyContent={'space-between'}
                      gap={2}
                      alignItems={'center'}
                    >
                      <Typography>
                        By ticking this box, I acknowledge, understand, accept
                        and agree tothe ESG Policy{' '}
                        <a href={ESGPolicy} target="blank">
                          (Download ESG Policy)
                        </a>
                      </Typography>
                      <Checkbox
                        onChange={(e) =>
                          setFieldValue('acceptESGPolicy', e.target.checked)
                        }
                        checked={values.acceptESGPolicy}
                      />
                    </Box>
                    <Box
                      className="innerInputContaine"
                      display="flex"
                      flexDirection="row"
                      justifyContent={'space-between'}
                      gap={2}
                      alignItems={'center'}
                    >
                      <Typography>
                        By ticking this box, I acknowledge, understand, accept
                        and agree to the Modern Slavery Policy{' '}
                        <a href={ModernSlaveryPolicy} target="blank">
                          (Download Modern Slavery Policy)
                        </a>
                      </Typography>
                      <Checkbox
                        onChange={(e) =>
                          setFieldValue(
                            'acceptModernSlaveryPolicy',
                            e.target.checked
                          )
                        }
                        checked={values.acceptModernSlaveryPolicy}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  handleModalState('declarationsAndConsentModal', 'close')
                }
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(
  DeclarationsAndConsentModal
);
