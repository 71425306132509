import { Typography, Button, Grid, Box } from '@mui/material';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';

type Props = {
  title: string;
  user?;
  stage;
};

function PageHeader({ title, user, stage }: Props) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="h3" component="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {/* {stage} */}
          </Typography>
        </Box>
        <Typography variant="subtitle2">
          {user?.firstName}, fill in the form to apply for the opening of{' '}
          {title}. You can exit the form at any point and come back later. Your
          application will be saved.
        </Typography>
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
}

export default connect((state: RootState) => ({
  user: state.authReducer.user
}))(PageHeader);
