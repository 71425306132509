import {
  CheckBoxRounded,
  DisabledByDefaultOutlined,
  DisabledByDefaultTwoTone,
  ErrorOutline,
  ErrorOutlined,
  SaveAltOutlined,
  SaveAltRounded,
  SaveAsTwoTone
} from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

type Props = {
  stage: string;
};

const FormStatus = ({ stage = 'notStarted' }: Props) => {
  if (stage === 'notStarted')
    return (
      <Tooltip title="Not Filled">
        <DisabledByDefaultTwoTone color="error" />
      </Tooltip>
    );
  if (stage === 'draft')
    return (
      <Tooltip title="In Draft">
        <SaveAsTwoTone color="warning" />
      </Tooltip>
    );
  if (stage === 'complete')
    return (
      <Tooltip title="Details Added">
        <CheckBoxRounded color="success" />
      </Tooltip>
    );
  if (stage === 'correction')
    return (
      <Tooltip title="Correction">
        <ErrorOutlined color="error" />
      </Tooltip>
    );

  return <></>;
};

export default FormStatus;
