import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Typography,
  Divider,
  MenuItem,
  Box,
  List,
  ListItem,
  Autocomplete
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import DateSelector from 'src/components/DateSelector';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { getSingleApplication } from 'src/redux/actions/applications';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { countryList } from 'src/data/countries';

type Props = {
  open: boolean;
  handleModalState: any;
  formData?;
  getSingleApplication;
};

const submitAddressHistory = async (values) => {
  try {
    await backend.post('/forms/applications/overseas-police-check', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const OverseasPoliceCheck = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('overseasPoliceCheckModal', 'close')}
    >
      <Formik
        initialValues={{
          everLivedOutsideUK: formData?.everLivedOutsideUK || 'No',
          locations: formData?.locations?.length
            ? formData?.locations?.map((location) => ({
                location: location.location || [],
                from: new Date(location.from) || '',
                to: new Date(location.to) || ''
              }))
            : [
                {
                  location: '',
                  from: new Date(),
                  to: new Date()
                }
              ]
        }}
        onSubmit={async (values) => {
          if (await submitAddressHistory({ ...values, applicationId })) {
            handleModalState('overseasPoliceCheckModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Overseas Police Check</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Grid container spacing={2} mt={2}>
                {/* Changed Address */}
                <Grid item xs={12}>
                  <TextField
                    name="everLivedOutsideUK"
                    value={values.everLivedOutsideUK}
                    fullWidth
                    onChange={handleChange}
                    size="small"
                    select
                    label="Have you been outside of The UK over 6 months In The Past
                      5 Years?"
                  >
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                  </TextField>
                </Grid>
                {values.everLivedOutsideUK === 'Yes' && (
                  <FieldArray name="locations">
                    {({ push, remove }) => {
                      return values.locations.map((location, idx) => {
                        return (
                          <React.Fragment key={location + idx}>
                            <Grid item xs={12}>
                              <Autocomplete
                                size="small"
                                fullWidth
                                id="location"
                                options={countryList}
                                value={values.locations[idx].location}
                                onChange={(_, values) =>
                                  setFieldValue(
                                    `locations.${idx}.location`,
                                    values
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ fontSize: '0.6rem' }}
                                    size="small"
                                    {...params}
                                    variant="outlined"
                                    label="Location"
                                    placeholder="Location"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DateSelector
                                label="From"
                                handleChange={(date) => {
                                  setFieldValue(`locations.${idx}.from`, date);
                                }}
                                value={values.locations[idx].from}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DateSelector
                                label="To"
                                handleChange={(date) => {
                                  setFieldValue(`locations.${idx}.to`, date);
                                }}
                                value={values.locations[idx].to}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                size="small"
                                sx={{
                                  display:
                                    idx === values.locations.length - 1
                                      ? 'block'
                                      : 'none'
                                }}
                                variant="outlined"
                                onClick={() => push({})}
                              >
                                Add more
                              </Button>
                            </Grid>
                            <Grid
                              alignContent={'end'}
                              // sx={{ display: idx === 0 && 'none' }}
                              item
                              xs={6}
                              textAlign={'right'}
                            >
                              <Button
                                sx={{ display: idx === 0 && 'none' }}
                                size="small"
                                onClick={() => remove(idx)}
                                variant="contained"
                                color="error"
                              >
                                Delete
                              </Button>
                            </Grid>
                          </React.Fragment>
                        );
                      });
                    }}
                  </FieldArray>
                )}
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  handleModalState('overseasPoliceCheckModal', 'close')
                }
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(OverseasPoliceCheck);
