import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';
import router from 'src/routing/router';

import { Box, CircularProgress, CssBaseline, Typography } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PrivateRoute from './routing/PrivateRoute';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import { useEffect } from 'react';
import { backend } from './services/http';
import store, { RootState } from './redux/store';
import { loadUser } from './redux/actions/auth';
import Application from './pages/application';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Signup from './pages/signup';
import Placeholder from './pages/placeholder';
import VerifyAccount from './pages/verify-account';
import { useSelector } from 'react-redux';
import ViewApplication from './pages/view';
import ResetPasword from './pages/reset-password';

function App() {
  useEffect(() => {
    // @ts-ignore
    store.dispatch(loadUser());
  }, []);

  const token = localStorage.getItem('formsAccessToken');
  if (token) {
    backend.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPasword />} />
          <Route path="/placeholder" element={<Placeholder />} />
          <Route
            path="/verify-account/:email/:verificationToken"
            element={<VerifyAccount />}
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/application/:id"
            element={
              <PrivateRoute>
                <Application />
              </PrivateRoute>
            }
          />

          <Route
            path="/view/:id"
            element={
              <PrivateRoute>
                <ViewApplication />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to={'/dashboard'} />} />
        </Routes>
        <ToastContainer theme={'colored'} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
