import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, Divider, MenuItem } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getSingleApplication } from 'src/redux/actions/applications';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { useParams } from 'react-router-dom';

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const submitemployments = async (values) => {
  try {
    await backend.post('/forms/applications/employment-history', {
      employments: values.employments,
      applicationId: values.applicationId
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const employments = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  console.log('Employmemnts Modal', formData);
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('employmentsModal', 'close')}
    >
      <Formik
        initialValues={{
          employments:
            formData?.employments?.length > 0
              ? formData?.employments?.map((employment) => ({
                  jobTitle: employment.jobTitle || '',
                  bandGrade: employment.bandGrade || '',
                  dateFrom: new Date(employment.dateFrom) || '',
                  dateTo: new Date(employment.dateTo) || '',
                  placeOfWork: employment.placeOfWork || '',
                  companyName: employment.companyName || '',
                  salary: employment.salary || '',
                  reasonForLeaving: employment.reasonForLeaving || ''
                }))
              : [
                  {
                    jobTitle: '',
                    bandGrade: '',
                    dateFrom: new Date(),
                    dateTo: new Date(),
                    placeOfWork: '',
                    companyName: '',
                    salary: '',
                    reasonForLeaving: ''
                  }
                ]
        }}
        onSubmit={async (values) => {
          if (await submitemployments({ ...values, applicationId })) {
            handleModalState('employmentsModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Employment History</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="subtitle2">
                Please list all jobs you have held for last 3 years starting
                with most recent.
              </Typography>
              <FieldArray name="employments">
                {({ push, remove }) => (
                  <div>
                    {values.employments.map((value, index) => {
                      return (
                        <Grid mt={2} key={index + 1} container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">
                              {index + 1} - Enter your employment details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="jobTitle"
                              name={`employments[${index}].jobTitle`}
                              label="Job Title"
                              fullWidth
                              variant="outlined"
                              value={value.jobTitle}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="bandGrade"
                              name={`employments[${index}].bandGrade`}
                              label="Band/Grade"
                              fullWidth
                              variant="outlined"
                              value={value.bandGrade}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              slotProps={{ textField: { size: 'small' } }}
                              onChange={(value) =>
                                setFieldValue(
                                  `employments[${index}].dateFrom`,
                                  value
                                )
                              }
                              value={value.dateFrom}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              slotProps={{ textField: { size: 'small' } }}
                              onChange={(value) =>
                                setFieldValue(
                                  `employments[${index}].dateTo`,
                                  value
                                )
                              }
                              value={value.dateTo}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="placeOfWork"
                              name={`employments[${index}].placeOfWork`}
                              label="Place of Work"
                              fullWidth
                              variant="outlined"
                              value={value.placeOfWork}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="companyName"
                              name={`employments[${index}].companyName`}
                              label="Company Name"
                              fullWidth
                              variant="outlined"
                              value={value.companyName}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              id="salary"
                              name={`employments[${index}].salary`}
                              label="Salary"
                              fullWidth
                              variant="outlined"
                              value={value.salary}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{ fontSize: '0.6rem' }}
                              size="small"
                              select
                              id="reasonForLeaving"
                              name={`employments[${index}].reasonForLeaving`}
                              label="Reason for Leaving Job"
                              fullWidth
                              variant="outlined"
                              value={value.reasonForLeaving}
                              onChange={handleChange}
                            >
                              <MenuItem value="I currently work here">
                                I currently work here
                              </MenuItem>
                              <MenuItem value="Better job opportunity">
                                Better job opportunity
                              </MenuItem>
                              <MenuItem value="Career advancement">
                                Career advancement
                              </MenuItem>
                              <MenuItem value="Higher salary or compensation">
                                Higher salary or compensation
                              </MenuItem>
                              <MenuItem value="Relocation/moving">
                                Relocation/moving
                              </MenuItem>
                              <MenuItem value="Company downsizing or restructuring">
                                Company downsizing or restructuring
                              </MenuItem>
                              <MenuItem value="Dissatisfaction with work environment/culture">
                                Dissatisfaction with work environment/culture
                              </MenuItem>
                              <MenuItem value="Lack of growth or development opportunities">
                                Lack of growth or development opportunities
                              </MenuItem>
                              <MenuItem value="Personal/family reasons">
                                Personal/family reasons
                              </MenuItem>
                              <MenuItem value="Health issues">
                                Health issues
                              </MenuItem>
                              <MenuItem value="Commute or transportation difficulties">
                                Commute or transportation difficulties
                              </MenuItem>
                              <MenuItem value="Change in career path or industry">
                                Change in career path or industry
                              </MenuItem>
                              <MenuItem value="Conflict with management or colleagues">
                                Conflict with management or colleagues
                              </MenuItem>
                              <MenuItem value="Burnout or excessive workload">
                                Burnout or excessive workload
                              </MenuItem>
                              <MenuItem value="Unfavorable work-life balance">
                                Unfavorable work-life balance
                              </MenuItem>
                              <MenuItem value="Returning to school or education pursuit">
                                Returning to school or education pursuit
                              </MenuItem>
                              <MenuItem value="Starting own business or entrepreneurship">
                                Starting own business or entrepreneurship
                              </MenuItem>
                              <MenuItem value="Contract or project-based employment completion">
                                Contract or project-based employment completion
                              </MenuItem>
                              <MenuItem value="Retirement">Retirement</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              size="small"
                              sx={{
                                display:
                                  index === values.employments.length - 1
                                    ? 'block'
                                    : 'none'
                              }}
                              variant="outlined"
                              onClick={() =>
                                push({
                                  dateFrom: new Date(),
                                  dateTo: new Date(),
                                  jobTitle: '',
                                  companyName: '',
                                  salary: '',
                                  reasonForLeaving: ''
                                })
                              }
                            >
                              Add more
                            </Button>
                          </Grid>
                          <Grid
                            alignContent={'end'}
                            item
                            xs={6}
                            textAlign={'right'}
                          >
                            <Button
                              sx={{ display: index === 0 && 'none' }}
                              onClick={() => remove(index)}
                              variant="contained"
                              color="error"
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </div>
                )}
              </FieldArray>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleModalState('employmentsModal', 'close')}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, {
  getSingleApplication
})(employments);
