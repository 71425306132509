import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  MenuItem,
  Select,
  Grid,
  Autocomplete,
  Divider,
  Input,
  Typography,
  IconButton
} from '@mui/material';
import { Form, Formik, useFormik } from 'formik';
import FileInput from 'src/components/FileUploader';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { backend } from 'src/services/http';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { getSingleApplication } from 'src/redux/actions/applications';
import { useParams } from 'react-router-dom';
import { DeleteOutline, Link } from '@mui/icons-material';
import FileUpload from 'src/components/FileUpload';
import { uploadFile } from 'src/services';
import DateSelector from 'src/components/DateSelector';

//add to dropdown Care Certificate, NVQ, CSTF Mandatory Training also add other speficy please
const skills = [
  'Community Health',
  'Pain Specialization',
  'First Aid',
  'CPR',
  'Emergency Care',
  'Care Certificate',
  'NVQ',
  'CSTF Mandatory Training',
  'Other'
];

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const submitEmployeePreferences = async (values) => {
  try {
    await backend.post('/forms/applications/employee-preferences', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const EmploymentPreferencesModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('employmentPreferencesModal', 'close')}
    >
      <Formik
        initialValues={{
          currentJob: formData?.currentJob || '',
          currentSalary: formData?.currentSalary || '',
          prefferedLocation: formData?.prefferedLocation || '',
          distanceWillingToTravelInMiles:
            formData?.distanceWillingToTravelInMiles || '',
          skillsAndTrainings: formData?.skillsAndTrainings || [],
          otherSkills: formData?.otherSkills || '',
          drivingLicense: formData?.drivingLicense || '',
          personalIndemnityCover: formData?.personalIndemnityCover || '',
          personalIndemnityOrganization:
            formData?.personalIndemnityOrganization || '',
          personalIndemnityPolicyNumber:
            formData?.personalIndemnityPolicyNumber || '',
          personalIndemnityExpiryDate:
            new Date(formData?.personalIndemnityExpiryDate) || new Date()
        }}
        onSubmit={async (values) => {
          if (await submitEmployeePreferences({ ...values, applicationId })) {
            handleModalState('employmentPreferencesModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle fontWeight={'bold'}>
              Employment Preferences
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography mb={2}>
                  Fields marked with (*) are mandatory and cannot be left blank
                </Typography>
                <Typography>
                  Under the Health Care and Associated Professions (Indemnity
                  Arrangements) Order 2014, all healthcare professionals are
                  legally required to confirm that they have relevant indemnity
                  insurance. This is to cover the different aspects of their
                  practice in order for them to register, or remain on a
                  register, with a professional regulatory body in the UK. We
                  request confirmation from the organisations that we supply
                  healthcare temporary workers of their guidelines for indemnity
                  insurance cover. If you do not hold indemnity insurance
                  through a professional organisation or other means, you must
                  ensure that we have confirmed to you that the organisation you
                  are being supplied to deems, your role in scope of their
                  indemnity cover. Without this confirmation of professional
                  indemnity insurance from either you or our client, we will be
                  unable to supply you for work
                </Typography>
              </DialogContentText>
              <Grid mt={2} container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="currentJob"
                    name="currentJob"
                    label="Current Job Title"
                    fullWidth
                    variant="outlined"
                    value={values.currentJob}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    type="number"
                    size="small"
                    id="currentSalary"
                    name="currentSalary"
                    label="Current Salary"
                    fullWidth
                    variant="outlined"
                    value={values.currentSalary}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="prefferedLocation"
                    name="prefferedLocation"
                    label="Preffered Work Location"
                    fullWidth
                    variant="outlined"
                    value={values.prefferedLocation}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    select
                    id="distanceWillingToTravelInMiles"
                    name="distanceWillingToTravelInMiles"
                    label="How far are you willing to travel? (in miles)"
                    value={values.distanceWillingToTravelInMiles}
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItem value={'1 Mile'}>1 Mile</MenuItem>
                    <MenuItem value={'2 Miles'}>2 Miles</MenuItem>
                    <MenuItem value={'5 Miles'}>5 Miles</MenuItem>
                    <MenuItem value={'10 Miles'}>10 Miles</MenuItem>
                    <MenuItem value={'20 Miles'}>20 Miles</MenuItem>
                    <MenuItem value={'30 Miles'}>30 Miles</MenuItem>
                    <MenuItem value={'50+ Miles'}>50+ Miles</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    // limitTags={2}
                    id="skillsAndTrainings"
                    options={skills}
                    value={values.skillsAndTrainings}
                    onChange={(_, values) => {
                      console.log(values);
                      setFieldValue('skillsAndTrainings', values);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ fontSize: '0.6rem' }}
                        size="small"
                        {...params}
                        variant="outlined"
                        label="Skills & Trainings"
                        placeholder="Skills & Trainings"
                      />
                    )}
                  />
                </Grid>
                {values.skillsAndTrainings.includes('Other') && (
                  <Grid item xs={12}>
                    <TextField
                      sx={{ fontSize: '0.6rem' }}
                      size="small"
                      id="otherSkills"
                      name="otherSkills"
                      label="Other Skills"
                      fullWidth
                      variant="outlined"
                      value={values.otherSkills}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    select
                    id="drivingLicense"
                    name="drivingLicense"
                    label="Do you hold a valid driving licence?"
                    fullWidth
                    variant="outlined"
                    value={values.drivingLicense}
                    onChange={handleChange}
                  >
                    <MenuItem value={'Yes'}>Yes</MenuItem>
                    <MenuItem value={'No'}>No</MenuItem>
                  </TextField>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    select
                    id="personalIndemnityCover"
                    name="personalIndemnityCover"
                    label="Do you hold a personal indemnity cover?"
                    fullWidth
                    variant="outlined"
                    value={values.personalIndemnityCover}
                    onChange={handleChange}
                  >
                    <MenuItem value={'Yes'}>Yes</MenuItem>
                    <MenuItem value={'No'}>No</MenuItem>
                  </TextField>
                </Grid>

                {values.personalIndemnityCover === 'Yes' && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ fontSize: '0.6rem' }}
                        size="small"
                        id="personalIndemnityOrganization"
                        name="personalIndemnityOrganization"
                        label="Personal Indemnity Organization"
                        fullWidth
                        variant="outlined"
                        value={values.personalIndemnityOrganization}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        sx={{ fontSize: '0.6rem' }}
                        size="small"
                        id="policyNumber"
                        name="personalIndemnityPolicyNumber"
                        label="Policy Number"
                        fullWidth
                        variant="outlined"
                        value={values.personalIndemnityPolicyNumber}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <DateSelector
                        label="Personal Indemnity Cover Expiration"
                        handleChange={(date) =>
                          setFieldValue('personalIndemnityExpiryDate', date)
                        }
                        value={values.personalIndemnityExpiryDate}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  handleModalState('employmentPreferencesModal', 'close')
                }
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, {
  getSingleApplication
})(EmploymentPreferencesModal);
