import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Box
} from '@mui/material';
import DateSelector from '../DateSelector';

function DateRangePicker({ title, open, setOpen, handleChange }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    // You can perform any actions when the date range changes here.
    // console.log('Selected Date Range:', startDate, endDate);
  }, [startDate, endDate]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = (e) => {
    handleChange(startDate, endDate);
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0.5}>
            <Grid item xs={6}>
              <DateSelector
                handleChange={(date) => setStartDate(new Date(date))}
                value={new Date(startDate)}
                label="Start Date"
              />
            </Grid>
            <Grid item xs={6}>
              <DateSelector
                handleChange={(date) => setEndDate(new Date(date))}
                value={new Date(endDate)}
                label="End Date"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Box p={2} textAlign="center">
          <Button
            disabled={!startDate || !endDate ? true : false}
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={handleApply}
          >
            Apply
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}

export default DateRangePicker;
