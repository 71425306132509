import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Divider,
  Autocomplete,
  MenuItem,
  Typography,
  Link
} from '@mui/material';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getSingleApplication } from 'src/redux/actions/applications';
import { backend } from 'src/services/http';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { useParams } from 'react-router-dom';
import CountryList from 'src/__mock__/countries.json';
import { countryList } from 'src/data/countries';
import DateSelector from 'src/components/DateSelector';

type Props = {
  open: boolean;
  handleModalState: any;
  getSingleApplication;
  formData;
};

const submitRightToWork = async (values) => {
  try {
    await backend.post('/forms/applications/right-to-work', values);
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const RightToWorkModal = ({
  open,
  handleModalState,
  getSingleApplication,
  formData
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('rightToWork', 'close')}
    >
      <Formik
        initialValues={{
          nationality: formData?.nationality || '',
          countryOfCitizenship: formData.countryOfCitizenship || '',
          visaType: formData?.visaType || '',
          otherVisaType: formData?.otherVisaType || '',
          visaExpiry: new Date(formData?.visaExpiry) || new Date(),
          visaRestrictions: formData?.visaRestrictions || '',
          visaIndefinteLeaveToRemainInCurrentPassport:
            formData?.visaIndefinteLeaveToRemainInCurrentPassport || 'No',
          shareCode: formData?.shareCode || '',
          consentToIdentityAndRightToWorkCheck:
            formData?.consentToIdentityAndRightToWorkCheck || 'Yes'
        }}
        onSubmit={async (values) => {
          if (await submitRightToWork({ ...values, applicationId })) {
            handleModalState('rightToWork', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ setFieldValue, values, handleChange }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Right To Work</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Grid mt={2} container spacing={2}>
                <Grid xs={12} item>
                  <TextField
                    fullWidth
                    size="small"
                    name="nationality"
                    value={values.nationality}
                    placeholder="Nationality"
                    label="Nationality"
                    onChange={handleChange}
                    select
                  >
                    <MenuItem value="British">British</MenuItem>
                    <MenuItem value="Non-British">Non-British</MenuItem>
                  </TextField>
                </Grid>
                {values.nationality === 'Non-British' && (
                  <>
                    <Grid xs={12} item>
                      <Autocomplete
                        size="small"
                        fullWidth
                        id="countryOfCitizenship"
                        options={countryList}
                        value={values.countryOfCitizenship}
                        onChange={(_, values) =>
                          setFieldValue('countryOfCitizenship', values)
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ fontSize: '0.6rem' }}
                            size="small"
                            {...params}
                            variant="outlined"
                            label="Country of Citizenship"
                            placeholder="Country of Citizenship"
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <TextField
                        fullWidth
                        size="small"
                        name="visaType"
                        value={values.visaType}
                        placeholder="Visa Type"
                        label="Visa Type"
                        onChange={handleChange}
                        select
                      >
                        <MenuItem value="Biometric Residence Permit (BRP)">
                          Biometric Residence Permit (BRP)
                        </MenuItem>
                        <MenuItem value="An ongoing application or appeal for leave to remain in the UK">
                          An ongoing application or appeal for leave to remain
                          in the UK
                        </MenuItem>
                        <MenuItem value="Certificate of Application (COA) issued following submission of an application under the EU Settlement Scheme (EUSS)">
                          Certificate of Application (COA) issued following
                          submission of an application under the EU Settlement
                          Scheme (EUSS)
                        </MenuItem>
                        <MenuItem value="Application Registration Card (ARC) for an asylum seeker">
                          Application Registration Card (ARC) for an asylum
                          seeker
                        </MenuItem>
                        <MenuItem value="Settled or pre-settled status under the EU Settlement Scheme (EUSS)">
                          Settled or pre-settled status under the EU Settlement
                          Scheme (EUSS)
                        </MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>
                    </Grid>
                    {values.visaType === 'Other' && (
                      <Grid xs={12} item>
                        <TextField
                          fullWidth
                          size="small"
                          name="otherVisaType"
                          value={values.otherVisaType}
                          placeholder="Other Visa Type"
                          label="Other Visa Type"
                          onChange={handleChange}
                        />
                      </Grid>
                    )}
                    <Grid xs={12} item>
                      <DateSelector
                        label="Visa Expiry"
                        value={values.visaExpiry}
                        handleChange={(date) =>
                          setFieldValue('visaExpiry', date)
                        }
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <TextField
                        fullWidth
                        size="small"
                        name="shareCode"
                        value={values.shareCode}
                        placeholder="Unique Online Share Code"
                        label="Unique Online Share Code"
                        onChange={handleChange}
                      />
                      <Typography my={1} color={'error'} variant="body2">
                        Dont know your <b>Share Code</b>?{' '}
                        <Link
                          href="https://www.gov.uk/prove-right-to-work"
                          target="blank"
                        >
                          Click here to get it.
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <TextField
                        multiline
                        rows={3}
                        fullWidth
                        size="small"
                        name="visaRestrictions"
                        value={values.visaRestrictions}
                        placeholder="Visa Restrictions (Please specify)"
                        label="Visa Restrictions (Please specify)"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <TextField
                        fullWidth
                        size="small"
                        name="visaIndefinteLeaveToRemainInCurrentPassport"
                        value={
                          values.visaIndefinteLeaveToRemainInCurrentPassport
                        }
                        placeholder="Visa/Indefinte Leave To Remain In Current Passport"
                        label="Visa/Indefinte Leave To Remain In Current Passport"
                        onChange={handleChange}
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid xs={12} item>
                      <TextField
                        fullWidth
                        size="small"
                        name="consentToIdentityAndRightToWorkCheck"
                        value={values.consentToIdentityAndRightToWorkCheck}
                        placeholder="Do you Give Pulse Care Consent to an Identity and Right To Work Check?"
                        label="Do you Give Pulse Care Consent to an Identity and Right To Work Check?"
                        onChange={handleChange}
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleModalState('rightToWork', 'close')}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(RightToWorkModal);
