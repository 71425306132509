import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Typography,
  Divider,
  MenuItem,
  Box,
  Checkbox,
  IconButton,
  Tooltip
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { InfoOutlined } from '@mui/icons-material';
import FileInput from 'src/components/FileUploader';
import { DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import { getSingleApplication } from 'src/redux/actions/applications';
import { RootState } from 'src/redux/store';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const submitCovid19Vaccination = async (values) => {
  try {
    await backend.post('/forms/applications/fitness-to-work', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const FitnessToWork = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('fitnessToWorkModal', 'close')}
    >
      <Formik
        initialValues={{
          fitToWork: formData?.fitToWork || '',
          receivedInfluenzaVaccine: formData?.receivedInfluenzaVaccine || 'No',
          receivedCovid19Vaccine: formData?.receivedCovid19Vaccine || 'No',
          covid19VaccinationDoseOneDate:
            new Date(formData?.covid19VaccinationDoseOneDate) || new Date(),
          covid19VaccinationDoseTwoDate:
            new Date(formData?.covid19VaccinationDoseTwoDate) || new Date()
        }}
        onSubmit={async (values) => {
          if (await submitCovid19Vaccination({ ...values, applicationId })) {
            handleModalState('fitnessToWorkModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Fitness to Work</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="subtitle1">
                Pulse Care will require you to complete an occupational health/
                medical questionnaire. This information needs to be provided
                along with your conﬁrmation of immunisation in order for our
                occupational health provider to review your ﬁtness for work.
              </Typography>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <Typography>Please tick the box to confirm</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box bgcolor={'#eee'} p={2} borderRadius={1}>
                    <Typography mb={2}>
                      I will ensure that I am healthy and fit to work shifts
                      offered to me and give my consent to Pulse Care releasing
                      my health and immunisation records for review to Qualified
                      Occupational Health Adviser. I understand that based on
                      this review I may be required to undergo a medical
                      examination to establish my fitness for work. I also
                      hereby consent to Pulse Care Limited obtaining further
                      information regarding my health from my GP or Occupational
                      Health Department.
                    </Typography>
                    <TextField
                      name="fitToWork"
                      value={values.fitToWork}
                      onChange={handleChange}
                      fullWidth
                      select
                      size="small"
                    >
                      <MenuItem value={'No'}>No</MenuItem>
                      <MenuItem value={'Yes'}>Yes</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <TextField
                    label="Have you received Influenza Vaccine in Last 12 months?"
                    placeholder="Have you received Influenza Vaccine in Last 12 months?"
                    name="receivedInfluenzaVaccine"
                    value={values.receivedInfluenzaVaccine}
                    onChange={handleChange}
                    fullWidth
                    select
                    size="small"
                  >
                    <MenuItem value={'No'}>No</MenuItem>
                    <MenuItem value={'Yes'}>Yes</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Have you received both doses of Covid-19 Vaccine?"
                    placeholder="Have you received both doses of Covid-19 Vaccine?"
                    name="receivedCovid19Vaccine"
                    value={values.receivedCovid19Vaccine}
                    onChange={handleChange}
                    fullWidth
                    select
                    size="small"
                  >
                    <MenuItem value={'No'}>No</MenuItem>
                    <MenuItem value={'Yes'}>Yes</MenuItem>
                  </TextField>
                </Grid>
                {values.receivedCovid19Vaccine === 'Yes' && (
                  <>
                    <Grid item xs={12}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: { size: 'small' }
                        }}
                        label="Date of Covid-19 Vaccination - Dose 1"
                        onChange={(date) =>
                          setFieldValue('covid19VaccinationDoseOneDate', date)
                        }
                        value={values.covid19VaccinationDoseOneDate}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: { size: 'small' }
                        }}
                        label="Date of Covid-19 Vaccination - Dose 2 (Optional)"
                        onChange={(date) =>
                          setFieldValue('covid19VaccinationDoseTwoDate', date)
                        }
                        value={values.covid19VaccinationDoseTwoDate}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleModalState('fitnessToWorkModal', 'close')}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(FitnessToWork);
