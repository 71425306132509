import { toast } from 'react-toastify';

export const renderHttpErrors = (error, autoClose?) => {
  if (error?.response?.data?.errors) {
    const errors = error.response.data.errors;
    errors.forEach((error) => toast(error.msg, { type: 'error', autoClose }));
  } else {
    toast('Bad request', { type: 'error', autoClose });
  }
};
