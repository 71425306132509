import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, MenuItem, Select, Grid } from '@mui/material';
import { Form, Formik, useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { backend } from 'src/services/http';
import { renderHttpErrors } from 'src/helpers/toast';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { getSingleApplication } from 'src/redux/actions/applications';
import { toast } from 'react-toastify';
import { RootState } from 'src/redux/store';
import DateSelector from 'src/components/DateSelector';
import { object, string, number, date, InferType } from 'yup';

type Props = {
  open: boolean;
  handleClickOpen: any;
  handleClose: any;
  formData;
  getSingleApplication;
};

const channels = [
  'Google / Search Engine',
  'Word of Mouth / Recommendation',
  'Online Advert',
  'Newspaper / Magazine / Leaflet',
  'Social Media',
  'Indeed',
  'Other Job Board',
  'Event / Exhibition'
];

const submitPersonalInformation = async (values) => {
  try {
    await backend.post('/forms/applications/personal-information', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

let validationSchema = object({
  title: string().required(),
  firstName: string().required('This field is required'),
  middleName: string().required('This field is required'),
  lastName: string().required('This field is required'),
  otherNames: string().required('This field is required'),
  landline: string().required('This field is required'),
  mobile: string().required('This field is required'),
  email: string().required('This field is required'),
  currentAddress: string().required('This field is required'),
  postCode: string().required('This field is required'),
  otherAddress: string().required('This field is required'),
  otherAddressPostCode: string().required('This field is required'),
  kin_name: string().required('This field is required'),
  kin_relationship: string().required('This field is required'),
  kin_mobile: string().required('This field is required'),
  kin_alternateNumber: string().required('This field is required'),
  kin_address: string().required('This field is required'),
  kin_postCode: string().required('This field is required'),
  howDidYouHearAboutUs: string().required('This field is required'),
  niNumber: string().required('This field is required')
});

const BasicInformationModal = ({
  open,
  handleClose,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <Formik
        initialValues={{
          title: formData.title || '',
          firstName: formData?.firstName || '',
          middleName: formData?.middleName || '',
          lastName: formData?.lastName || '',
          otherNames: formData?.otherNames || '',
          dob: new Date(formData.dob) || new Date(),
          landline: formData?.landline || '',
          mobile: formData?.mobile || '',
          email: formData?.email || '',
          currentAddress: formData?.currentAddress || '',
          postCode: formData?.postCode || '',
          otherAddress: formData?.otherAddress || '',
          otherAddressPostCode: formData?.otherAddressPostCode || '',
          kin_name: formData?.kin_name || '',
          kin_relationship: formData?.kin_relationship || '',
          kin_mobile: formData?.kin_mobile || '',
          kin_alternateNumber: formData?.kin_alternateNumber || '',
          kin_address: formData?.kin_address || '',
          kin_postCode: formData?.kin_postCode || '',
          howDidYouHearAboutUs: formData?.howDidYouHearAboutUs || '',
          niNumber: formData?.niNumber || ''
        }}
        // validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (await submitPersonalInformation({ ...values, applicationId })) {
            handleClose(false);
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue, errors }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle fontWeight={'bold'}>Personal Information</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Grid mt={2} container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    select
                    id="title"
                    name="title"
                    label="Title"
                    fullWidth
                    autoComplete="title"
                    variant="outlined"
                    value={values.title}
                    onChange={handleChange}
                    error={errors.title && Boolean(errors.title)}
                    helperText={errors.title}
                  >
                    <MenuItem value={'Mr'}>Mr</MenuItem>
                    <MenuItem value={'Mrs'}>Mrs</MenuItem>
                    <MenuItem value={'Miss'}>Miss</MenuItem>
                    <MenuItem value={'Ms'}>Ms</MenuItem>
                    <MenuItem value={'Dr'}>Dr</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    fullWidth
                    autoComplete="first-name"
                    variant="outlined"
                    value={values.firstName}
                    onChange={handleChange}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="middleName"
                    name="middleName"
                    label="Middle Name"
                    fullWidth
                    autoComplete="middle-name"
                    variant="outlined"
                    value={values.middleName}
                    onChange={handleChange}
                    error={errors.middleName && Boolean(errors.middleName)}
                    helperText={errors.middleName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    required
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    autoComplete="last-name"
                    variant="outlined"
                    value={values.lastName}
                    onChange={handleChange}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="otherNames"
                    name="otherNames"
                    label="Other Names"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.otherNames}
                    onChange={handleChange}
                    error={errors.otherNames && Boolean(errors.otherNames)}
                    helperText={errors.otherNames}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateSelector
                    handleChange={(date) => {
                      setFieldValue(`dob`, date);
                    }}
                    value={values.dob}
                    label="Date of Birth"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="landline"
                    name="landline"
                    label="Landline"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.landline}
                    onChange={handleChange}
                    error={errors.landline && Boolean(errors.landline)}
                    helperText={errors.landline}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="mobile"
                    name="mobile"
                    label="Mobile"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.mobile}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="email"
                    name="email"
                    label="Email"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="currentAddress"
                    name="currentAddress"
                    label="Current Address"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.currentAddress}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="postCode"
                    name="postCode"
                    label="Post Code"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.postCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="otherAddress"
                    name="otherAddress"
                    label="Other Address"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.otherAddress}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="otherAddressPostCode"
                    name="otherAddressPostCode"
                    label="Other Address Post Code"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.otherAddressPostCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="kin_name"
                    name="kin_name"
                    label="Kin / Emergency Contact Name"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.kin_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="kin_relationship"
                    name="kin_relationship"
                    label="Kin Relationship"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.kin_relationship}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="kin_mobile"
                    name="kin_mobile"
                    label="Kin Mobile Number"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.kin_mobile}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="kin_alternateNumber"
                    name="kin_alternateNumber"
                    label="Kin Alternate Number"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.kin_alternateNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="kin_address"
                    name="kin_address"
                    label="Kin Address"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.kin_address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="kin_postCode"
                    name="kin_postCode"
                    label="Kin Post Code"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.kin_postCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="howDidYouHearAboutUs"
                    name="howDidYouHearAboutUs"
                    label="How did you hear about us?"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.howDidYouHearAboutUs}
                    onChange={handleChange}
                  >
                    {channels.map((channel) => (
                      <MenuItem key={channel} value={channel}>
                        {channel}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ fontSize: '0.6rem' }}
                    size="small"
                    id="niNumber"
                    name="niNumber"
                    label="National Insurance Number"
                    fullWidth
                    autoComplete="other-names"
                    variant="outlined"
                    value={values.niNumber}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(BasicInformationModal);
