import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import ApplicationForm from './form';
import { useParams } from 'react-router';
import { applications_mock } from 'src/__mock__/data';
import { useEffect, useState } from 'react';
import { IApplication } from 'src/interfaces/Application';
import { getSingleApplication } from 'src/redux/actions/applications';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import Loading from 'src/components/Loading';

type Props = {
  getSingleApplication;
  currentApplication;
  loading;
};

function Application({
  getSingleApplication,
  currentApplication,
  loading
}: Props) {
  const params: any = useParams();
  useEffect(() => {
    getSingleApplication(params.id);
  }, []);

  return !loading ? (
    <>
      <Helmet>
        <title>Application</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader
          title={currentApplication.application.job.title}
          stage={currentApplication.application.stage}
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <ApplicationForm />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  ) : (
    <Loading text="Please wait..." />
  );
}

export default connect(
  (state: RootState) => ({
    currentApplication: state.applicationsReducer.currentApplication,
    loading: state.applicationsReducer.currentApplicationLoading
  }),
  { getSingleApplication }
)(Application);
