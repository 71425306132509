import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Typography,
  Divider,
  MenuItem,
  Box,
  Autocomplete,
  Tooltip,
  IconButton,
  Checkbox,
  List,
  ListItem
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import countries from 'src/__mock__/countries.json';
import { InfoOutlined } from '@mui/icons-material';
import FileInput from 'src/components/FileUploader';
import React from 'react';
import DateSelector from 'src/components/DateSelector';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/helpers/toast';
import { backend } from 'src/services/http';
import { getSingleApplication } from 'src/redux/actions/applications';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';

type Props = {
  open: boolean;
  handleModalState: any;
  formData;
  getSingleApplication;
};

const startIndex = 100;
const previousAddressFields = {
  dateMovedIn: new Date(),
  dateMovedOut: new Date(),
  addressLine1: '',
  addressLine2: '',
  cityTown: '',
  country: '',
  postCode: ''
};

const submitAddressHistory = async (values) => {
  try {
    await backend.post('/forms/applications/declarations', {
      ...values
    });
    toast('Submission successfull', { type: 'success' });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const DeclarationsModal = ({
  open,
  handleModalState,
  formData,
  getSingleApplication
}: Props) => {
  const params = useParams();
  const applicationId = params.id;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleModalState('declarationsModal', 'close')}
    >
      <Formik
        initialValues={{
          anyConvictions: 'No',
          awareOfPreviousInvestigations: 'No',
          unspentConvictions: 'No',
          disclosureScotland: 'No',
          awareOfProceedings: 'No',
          awareOfCurrentInvestigations: 'No',
          everSuspended: 'No',
          anyPendingActionsAgainstYou: 'No'
        }}
        onSubmit={async (values) => {
          if (await submitAddressHistory({ ...values, applicationId })) {
            handleModalState('declarationsModal', 'close');
            getSingleApplication(applicationId);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Declarations</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Typography variant="subtitle1">
                Please provide your complete 5 year address history with correct
                postcodes (UK and outside UK)
              </Typography>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    bgcolor={'#f8f8f8'}
                    p={2}
                    borderRadius={1}
                  >
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        Do you have any convictions, cautions, reprimands,
                        warnings or additional information that are not
                        'protected' as deﬁned by the rehabilitation of oﬀenders
                        act 1974 (exceptions) order 1975 (2013) on your current
                        DBS, update service or disclosure Scotland?
                      </Typography>
                      <TextField
                        name="anyConvictions"
                        value={values.anyConvictions}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        Are you aware of any previous, current or pending
                        investigations, police enquiries or legal proceedings
                        following allegations made against you (in the UK or any
                        other country?)
                      </Typography>
                      <TextField
                        name="awareOfPreviousInvestigations"
                        value={values.awareOfPreviousInvestigations}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        For basic check (non-patient care facing) do you have
                        any unspent convictions?
                      </Typography>
                      <TextField
                        name="unspentConvictions"
                        value={values.unspentConvictions}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        Disclosure Scotland only (PVG scheme - disclosure
                        Scotland) do you have any spent or unspent convictions?
                      </Typography>
                      <TextField
                        name="disclosureScotland"
                        value={values.disclosureScotland}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        Are you aware if you ever had any proceedings brought
                        against you in work or personal life, in the UK or any
                        other country that could be considered a risk to
                        safeguarding or children or vulnerable adults?
                      </Typography>
                      <TextField
                        name="awareOfProceedings"
                        value={values.awareOfProceedings}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        Are you aware of any current investigations by any
                        employer/organisation or professional regulatory body in
                        the UK or any other country?
                      </Typography>
                      <TextField
                        name="awareOfCurrentInvestigations"
                        value={values.awareOfCurrentInvestigations}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        Have you been suspended/terminated/had restrictions
                        placed on your practice from employment/organisations or
                        professional regulatory body(s) in the UK or any other
                        country?
                      </Typography>
                      <TextField
                        name="everSuspended"
                        value={values.everSuspended}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                    <Box
                      className="innerInputContainer"
                      display={'flex'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <Typography>
                        Have You Any Pending Or Previous ﬁnancial Or Civil
                        Actions Brought Against You?
                      </Typography>
                      <TextField
                        name="anyPendingActionsAgainstYou"
                        value={values.anyPendingActionsAgainstYou}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                        select
                      >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                      </TextField>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleModalState('declarationsModal', 'close')}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(null, { getSingleApplication })(DeclarationsModal);
