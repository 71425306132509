export const formSteps = {
  PERSONAL_INFORMATION: 'Personal information',
  PREFERENCES_AND_AVAILABILITY: 'Preferences and availiability',
  RIGHT_TO_WORK: 'Right to work',
  PAYMENT_INFO: 'Payment info',
  WORK_HISTORY_AND_REFERENCES: 'Work history and references',
  FITNESS_TO_WORK: 'Fitness to work',
  CRIMINAL_RECORD_CHECK: 'Criminal record check',
  PROFESSIONAL_INFORMATION: 'Professional Information',
  DOCUMENTS: 'Documents',
  EMPLOYEE_DECLARATION: 'Employee Decleration'
};
